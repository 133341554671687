import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  ButtonComponent,
  generateNotification,
  InputComponent,
} from "stempl-component-library";
import { generateNewProviderForRegister } from "../utils/user/provider/Provider.utils";
import { registerNewProvider } from "../utils/user/User.firebase";
import {
  Branche,
  PrivateProviderInformation,
  Provider,
} from "../utils/user/User.types";
import { ReactComponent as EyeIcon } from "../assets/icons/visibility.svg";
import { ReactComponent as EyeIconOff } from "../assets/icons/visibility-off.svg";

interface ProviderRegisterFormProps {
  displayName?: string;
  branche?: Branche;
  color?: string;
  logoName?: string;
  reward?: string;
  skipCardDesign?: boolean;
  discountedRegister?: boolean;
}

const ProviderRegisterForm: React.FC<ProviderRegisterFormProps> = ({
  displayName,
  branche,
  color,
  logoName,
  reward,
  skipCardDesign,
  discountedRegister,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [newProvider, setNewProvider] = useState<Provider>(
    generateNewProviderForRegister(discountedRegister)
  );
  const [isLoading, toggleIsLoading] = useState<boolean>(false);
  const [showPrivateInformation, toggleShowPrivateInformation] =
    useState<boolean>(false);
  const [passwordShown, togglePasswordShown] = useState<boolean>(false);

  /**
   * Helper to show the password entered by the user
   */
  const togglePassword = (): void => {
    togglePasswordShown(!passwordShown);
  };

  //sets states of provider according to incoming props
  useEffect(() => {
    if (!newProvider) return;
    if (displayName) newProvider.displayName = displayName;
    if (branche) newProvider.branche = branche;
    if (color) newProvider.color = color;
    if (reward) newProvider.cardDiscount = reward;
    setNewProvider({ ...newProvider });
    // eslint-disable-next-line
  }, [displayName, branche, color]);

  /**
   * Helper to update privateInformation Info of provider
   * @param privateInformation
   */
  const setPrivateInformation = (
    privateInformation: PrivateProviderInformation
  ): void =>
    setNewProvider({
      ...newProvider!,
      privateInformation: privateInformation,
    });

  return showPrivateInformation ? (
    <div className="login-form">
      <form
        onSubmit={(evt) => {
          evt.preventDefault();
          if (password !== passwordConfirm) {
            generateNotification(t("notifications.notSamePasswords"), "info");
            return;
          }
          toggleIsLoading(true);
          registerNewProvider(newProvider, password).then((success) => {
            if (success)
              navigate("/register", {
                state: {
                  logoName: logoName,
                  skipCardDesign: skipCardDesign,
                },
              });
            else toggleShowPrivateInformation(false);
            toggleIsLoading(false);
          });
        }}
      >
        <InputComponent
          name="fname"
          value={newProvider.privateInformation!.firstName}
          required
          label={t("pages.login.firstName")}
          onChange={(newName) =>
            setPrivateInformation({
              ...newProvider.privateInformation!,
              firstName: newName,
            })
          }
        />
        <InputComponent
          name="lname"
          value={newProvider.privateInformation!.lastName}
          required
          label={t("pages.login.lastName")}
          onChange={(newName) =>
            setPrivateInformation({
              ...newProvider.privateInformation!,
              lastName: newName,
            })
          }
        />
        <InputComponent
          value={newProvider.displayName}
          required
          label={t("pages.login.displayName")}
          onChange={(newName) =>
            setNewProvider({
              ...newProvider,
              displayName: newName,
            })
          }
        />
        <InputComponent
          name="street"
          value={newProvider.privateInformation!.street}
          required
          label={t("pages.login.street")}
          onChange={(newStreet) =>
            setPrivateInformation({
              ...newProvider.privateInformation!,
              street: newStreet,
            })
          }
        />
        <InputComponent
          name="zip"
          value={newProvider.privateInformation!.zip}
          required
          label={t("pages.login.zip")}
          onChange={(newZip) =>
            setPrivateInformation({
              ...newProvider.privateInformation!,
              zip: newZip,
            })
          }
        />
        <InputComponent
          name="city"
          value={newProvider.privateInformation!.city}
          required
          label={t("pages.login.city")}
          onChange={(newCity) =>
            setPrivateInformation({
              ...newProvider.privateInformation!,
              city: newCity,
            })
          }
        />
        <br />
        <div className="button-wrapper">
          <ButtonComponent
            color="grey"
            value={t("buttons.back")}
            onClick={() => toggleShowPrivateInformation(false)}
          />
          <ButtonComponent
            type="submit"
            value={t("buttons.register")}
            isLoading={isLoading}
          />
        </div>
      </form>
    </div>
  ) : (
    <div className="login-form">
      <h1>{t("pages.login.title")}</h1>
      <p>
        {t(
          `pages.login.${
            discountedRegister
              ? "contentUserDiscountRegister"
              : "contentUserRegister"
          }`
        )}
      </p>
      <form
        onSubmit={(evt) => {
          evt.preventDefault();
          toggleShowPrivateInformation(true);
        }}
      >
        <InputComponent
          value={newProvider.privateInformation!.firstName}
          required
          label={t("pages.login.firstName")}
          onChange={(newName) =>
            setNewProvider({
              ...newProvider,
              privateInformation: {
                ...newProvider.privateInformation!,
                firstName: newName,
              },
            })
          }
        />
        <InputComponent
          value={newProvider.privateInformation!.email}
          label={t("general.mail")}
          required
          type="email"
          onChange={(newMail) =>
            setNewProvider({
              ...newProvider,
              privateInformation: {
                ...newProvider.privateInformation!,
                email: newMail,
              },
            })
          }
        />
        <div className="login-form--password">
          <InputComponent
            value={password}
            label={t("general.password")}
            type={passwordShown ? "text" : "password"}
            onChange={setPassword}
            required
          />
          <div className="login-form--password--icon">
            {passwordShown ? (
              <EyeIconOff onClick={togglePassword} />
            ) : (
              <EyeIcon onClick={togglePassword} />
            )}
          </div>
        </div>
        <div className="login-form--password-confirm">
          <InputComponent
            value={passwordConfirm}
            label={t("general.passwordConfirm")}
            type={passwordShown ? "text" : "password"}
            onChange={setPasswordConfirm}
            required
          />
        </div>
        <br />
        <ButtonComponent type="submit" value={t("buttons.startRegister")} />
      </form>
    </div>
  );
};

export default ProviderRegisterForm;

import { Timestamp } from "firebase/firestore";
import i18next from "i18next";
import {
  generateNotification,
  PickerOption,
  Theme,
} from "stempl-component-library";
import { ReactComponent as BakeryIcon } from "../../../assets/branche/bakery.svg";
import { ReactComponent as CinemaIcon } from "../../../assets/branche/cinema.svg";
import { ReactComponent as DrinkIcon } from "../../../assets/branche/drink.svg";
import { ReactComponent as FlowerIcon } from "../../../assets/branche/flower.svg";
import { ReactComponent as FoodIcon } from "../../../assets/branche/food.svg";
import { ReactComponent as GymIcon } from "../../../assets/branche/gym.svg";
import { ReactComponent as HairIcon } from "../../../assets/branche/hair.svg";
import { ReactComponent as IceIcon } from "../../../assets/branche/ice.svg";
import { ReactComponent as SwimIcon } from "../../../assets/branche/swim.svg";
import { ReactComponent as QR1Icon } from "../../../assets/icons/QR1.svg";
import { ReactComponent as QR4Icon } from "../../../assets/icons/QR4.svg";
import { ReactComponent as QR9Icon } from "../../../assets/icons/QR9.svg";
import { ReactComponent as Logo } from "../../../assets/logo-monochrome.svg";
import { Branche, Provider, UserType } from "../User.types";

/**
 * Builds PickerOptions with correct Logos for all branches
 * @returns Picker options with logos for branches
 * @tested
 */
export const buildPickerOptionsForBranches = (): PickerOption[] => {
  const options: PickerOption[] = [];
  options.push({
    value: Branche.GYM,
    content: <GymIcon />,
  });
  options.push({
    value: Branche.ICE,
    content: <IceIcon />,
  });
  options.push({
    value: Branche.BAKERY,
    content: <BakeryIcon />,
  });
  options.push({
    value: Branche.SWIM,
    content: <SwimIcon />,
  });
  options.push({
    value: Branche.CINEMA,
    content: <CinemaIcon />,
  });
  options.push({
    value: Branche.FLOWER,
    content: <FlowerIcon />,
  });
  options.push({
    value: Branche.FOOD,
    content: <FoodIcon />,
  });
  options.push({
    value: Branche.HAIR,
    content: <HairIcon />,
  });
  options.push({
    value: Branche.DRINK,
    content: <DrinkIcon />,
  });
  return options;
};

/**
 * Helper to get Icon for branche
 * @param branche
 * @returns Icon for given branche
 * @tested
 */
export const getIconForBranche = (
  branche: Branche
): JSX.Element | undefined => {
  switch (branche) {
    case Branche.GYM:
      return <GymIcon />;
    case Branche.ICE:
      return <IceIcon />;
    case Branche.BAKERY:
      return <BakeryIcon />;
    case Branche.SWIM:
      return <SwimIcon />;
    case Branche.CINEMA:
      return <CinemaIcon />;
    case Branche.FLOWER:
      return <FlowerIcon />;
    case Branche.FOOD:
      return <FoodIcon />;
    case Branche.HAIR:
      return <HairIcon />;
    case Branche.DRINK:
      return <DrinkIcon />;
    case Branche.OTHER:
    default:
      return undefined;
  }
};

/**
 * Helper method to init a new provider instance
 *
 * @param discountedRegister Optionally set the discount flag to true
 * @returns An empty provider
 * @tested
 */
export const generateNewProviderForRegister = (
  discountedRegister: boolean = false
): Provider => {
  const targetDate: Date = new Date();
  //target date is supposed to be 7 days in the future, giving them 7 days to put in their payment info
  targetDate.setDate(targetDate.getDate() + 7);
  return {
    registerFinished: false,
    type: UserType.PROVIDER,
    uid: undefined!,
    branche: Branche.GYM,
    color: "#a2be46",
    displayName: "",
    cardDiscount: "-5%",
    theme: Theme.AUTO,
    privateInformation: {
      stemplerUids: [],
      stemplAmount: 0,
      activatedCardsAmount: 0,
      email: "",
      authProvider: "local",
      firstName: "",
      city: "",
      lastName: "",
      street: "",
      zip: "",
      daysUntilPaymentInformation: Timestamp.fromDate(targetDate),
      registerIsDiscounted: discountedRegister,
    },
  };
};

/**
 * Helper to get PickerOption with Logo for Provider
 * @returns Option with Logo of provider OR stempl-logo as fallback
 * @tested
 */
export const getPickerOptionWithLogo = (path?: string): PickerOption => {
  if (path)
    return {
      value: Branche.OTHER,
      content: <img className="stempl-image" src={path} alt="Logo" />,
    };
  return {
    value: Branche.OTHER,
    content: <Logo className="logo-on-color" />,
  };
};

/**
 * generates URL for provider to give Stempls
 * @param providerId
 * @returns URL for provider QR Code
 */
export const getQrContentForProviderId = (providerId: string): string => {
  //generate qr content
  const stemplUrl: string = process.env.REACT_APP_URL || "";
  if (!stemplUrl) {
    generateNotification(i18next.t("notifications.noStemplUrl"), "warning");
    return "";
  }
  return `https://${stemplUrl}/provider/${providerId}`;
};

/**
 * Helper to build default options for card value
 * @returns default options for cardValue
 * @tested
 */
export const buildDefaultOptionsForCardDiscount = (): PickerOption[] => {
  const options: PickerOption[] = [];
  options.push({
    value: "-5%",
    content: "-5%",
  });
  options.push({
    value: "-10%",
    content: "-10%",
  });
  options.push({
    value: "-15%",
    content: "-15%",
  });
  return options;
};

/**
 * Helper to build Picker options for amount of qr codes in layout
 * @returns default options for QR amounts
 * @tested
 */
export const buildPickerOptionsForQRCodeAmount = (): PickerOption[] => {
  const options: PickerOption[] = [];
  options.push({
    value: "1",
    content: <QR1Icon />,
  });
  options.push({
    value: "4",
    content: <QR4Icon />,
  });
  options.push({
    value: "9",
    content: <QR9Icon />,
  });
  return options;
};

/**
 * Util method to calculate the amount of days between today and the given
 * time stamp
 *
 * @param timeStamp The target date timestampt
 * @returns the amount of days between today and the target
 * @tested
 */
export const getDaysLeftUntilTimeStamp = (timeStamp: Timestamp): number => {
  const expireDate: Date = timeStamp.toDate();
  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;
  // Calculating the time difference between two dates
  const diffInTime = expireDate.getTime() - new Date().getTime();
  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);
  return Math.abs(diffInDays);
};

/**
 * Util to compare the given discount key against the configurated key
 *
 * @param discountKey The discount key as it was given to the register
 * @returns true if the requesting provider gets a month free
 */
export const providerAppliesForOneMonthFree = (
  discountKey: string
): boolean => {
  const targetKey: string =
    process.env.REACT_APP_PROVIDER_REGISTER_ONE_MONTH_DISCOUNT!;
  console.log(targetKey, discountKey);
  return discountKey === targetKey;
};

import { useTranslation } from "react-i18next";
import { ButtonComponent, CardComponent } from "stempl-component-library";
import { CardComponentProps } from "stempl-component-library/build/CardComponent/CardComponent.types";

interface RedeemDiscountProps {
  cardProps: CardComponentProps;
  onDone(): void;
}

const RedeemDiscount: React.FC<RedeemDiscountProps> = ({
  onDone,
  cardProps,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <h2>{t("pages.dashboard.stempler.fulfilledCard.header")}</h2>
      <div>{t("pages.dashboard.stempler.fulfilledCard.text")}</div>
      <br />
      <div className="centered">
        <CardComponent showFulfilled {...cardProps} />
      </div>
      <br />
      <br />
      <ButtonComponent value={t("buttons.done")} onClick={onDone} />
    </div>
  );
};

export default RedeemDiscount;

import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import {
  AnimatedCounterComponent,
  BoxComponent,
  ButtonComponent,
  LoaderComponent,
  PickerComponent,
} from "stempl-component-library";
import { startCheckout } from "../utils/StripeUtils";
import {
  buildPickerOptionsForQRCodeAmount,
  getDaysLeftUntilTimeStamp,
} from "../utils/user/provider/Provider.utils";
import { Provider } from "../utils/user/User.types";
import { UserContext } from "./PrivateLayoutWrapper";

interface ProviderDashboardProps {
  provider: Provider;
}

const ProviderDashboard: React.FC<ProviderDashboardProps> = ({ provider }) => {
  const { updateUser } = useContext(UserContext);
  const { t } = useTranslation();
  const [amountOfQrCodes, setAmountOfQrCodes] = useState<number>(4);
  const cookieName: string = process.env.REACT_APP_PROVIDER_REGISTER_COOKIE!;
  // eslint-disable-next-line
  const [_, __, removeCookie] = useCookies([cookieName]);
  const [showStripeLoader, toggleStripeLoader] = useState<boolean>(false);

  // on page load refresh the provider values and in case there
  // is still a cookie from during the registration delete this as well
  useEffect(() => {
    updateUser();
    removeCookie(cookieName, { path: "/register" });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <h1>{provider.displayName}</h1>
      <br />
      {provider.privateInformation?.daysUntilPaymentInformation && (
        <BoxComponent>
          <div
            className="flex-column-center"
            onClick={() => {
              toggleStripeLoader(true);
              startCheckout(
                provider.uid,
                () => toggleStripeLoader(false),
                provider.privateInformation?.registerIsDiscounted || false,
                true
              );
            }}
          >
            {showStripeLoader ? (
              <LoaderComponent />
            ) : (
              <>
                <p className="red-text">
                  {t("pages.dashboard.provider.missingPaymentWarning", {
                    replace: {
                      daysLeft: getDaysLeftUntilTimeStamp(
                        provider.privateInformation?.daysUntilPaymentInformation
                      ),
                    },
                  })}
                </p>
                <p>{t("pages.dashboard.provider.missingPaymentCta")}</p>
              </>
            )}
          </div>
        </BoxComponent>
      )}
      {provider.deleteDate && (
        <BoxComponent>
          <p className="red-text">
            {t("pages.dashboard.provider.deletedAccountTitle")}
          </p>
          <p>
            {t("pages.dashboard.provider.deletedAccountBody", {
              replace: {
                endDate: provider.deleteDate.toDate().toLocaleDateString(),
              },
            })}
          </p>
          <p>{t("pages.dashboard.provider.deletedAccountCta")}</p>
          <ButtonComponent
            value={t("pages.dashboard.provider.deletedAccountButton")}
          />
        </BoxComponent>
      )}
      <br />
      <BoxComponent>
        <div className="flex-column-center">
          <h2>{t("pages.dashboard.provider.stemplerIds")}</h2>
          <div className="spacer" />
          <AnimatedCounterComponent
            targetNumber={provider.privateInformation?.stemplerUids?.length}
          />
        </div>
      </BoxComponent>
      <br />
      <BoxComponent>
        <div className="flex-column-center">
          <h2>{t("pages.dashboard.provider.stemplAmount")}</h2>
          <div className="spacer" />
          <AnimatedCounterComponent
            targetNumber={provider.privateInformation?.stemplAmount}
          />
        </div>
      </BoxComponent>
      <br />
      <BoxComponent>
        <div className="flex-column-center">
          <h2>{t("pages.dashboard.provider.givenDiscounts")}</h2>
          <div className="spacer" />
          <AnimatedCounterComponent
            targetNumber={provider.privateInformation?.activatedCardsAmount}
          />
        </div>
      </BoxComponent>
      <br />
      <BoxComponent>
        <form
          onSubmit={(evt) => {
            evt.preventDefault();
            window.open(
              `/qrcode/${encodeURIComponent(
                provider.displayName
              )}/${encodeURIComponent(provider.uid)}/${amountOfQrCodes}`,
              "_blank"
            );
          }}
        >
          <h1>{t("pages.dashboard.generateQrCode")}</h1>
          <div>{t("pages.dashboard.amountOfQrCodes")}</div>
          <PickerComponent
            options={buildPickerOptionsForQRCodeAmount()}
            setSelectedOption={(value) => setAmountOfQrCodes(parseInt(value))}
            selectedOption={amountOfQrCodes.toString()}
          />
          <br />
          <ButtonComponent value={t("buttons.display")} type="submit" />
        </form>
      </BoxComponent>
    </>
  );
};
export default ProviderDashboard;

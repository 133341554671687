import { BoxComponent } from "stempl-component-library";
import { ReactComponent as Arrow } from "../assets/icons/down-arrow.svg";
import { ReactComponent as Logo } from "../assets//logo.svg";
import { useTranslation } from "react-i18next";

const NoStempl: React.FC<{ onClick(): void }> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <div className="no-stempl__wrapper">
      <span>{t("pages.dashboard.stempler.noStemplTitle")}</span>
      <Arrow className="no-stempl__arrow" />
      <BoxComponent>
        <Logo className="no-stempl__logo" onClick={() => onClick()} />
      </BoxComponent>
    </div>
  );
};

export default NoStempl;

import { FirebaseError } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  User,
  UserCredential,
} from "firebase/auth";
import {
  addDoc,
  collection,
  CollectionReference,
  doc,
  DocumentData,
  getDocs,
  Query,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  ref,
  StorageReference,
  uploadBytes,
} from "firebase/storage";
import i18next from "i18next";
import { generateNotification, Theme } from "stempl-component-library";
import { auth, db, storage, tempStorage } from "../../firebase";
import {
  PrivateProviderInformation,
  PrivateUserInformation,
  Provider,
  Stempler,
  UserType,
  User as AppUser,
} from "./User.types";

/**
 * The database reference for user (provider and stempler)
 */
const userDatabase: string = process.env.REACT_APP_USER_DATABASE!;

/**
 * The database identifier in which all user ids to delete are stored
 */
const userDeleteDatabase: string = process.env.REACT_APP_USER_DELETE_DATABASE!;

/**
 * API method to init a new stempler based upon mail and password
 * and persist it on the database.
 *
 * @param newStempler The new stempler instance
 * @param password The user entered password
 * @returns true if successful, false otherwise
 */
export const registerNewStemplerWithPassword = async (
  newStempler: Stempler,
  password: string
): Promise<boolean> => {
  try {
    const user: User | undefined = await createAuthUser(
      newStempler.privateInformation!.email,
      password
    );
    if (!user) return false;
    const { privateInformation, ...publicInformation } = newStempler;
    await setDoc(doc(db, userDatabase, user.uid), {
      ...publicInformation,
      uid: user.uid,
    } as Stempler);
    const userPrivateRef: CollectionReference = collection(
      db,
      userDatabase,
      user.uid,
      "privateInformation"
    );
    await addDoc(userPrivateRef, {
      ...privateInformation,
      authProvider: "local",
    } as PrivateUserInformation);
    return true;
  } catch (err: any) {
    console.error("Error during stempler creation", err);
    return false;
  }
};

/**
 * API method to upload a new logo image. It sets implicitly the
 * user id as filename and deletes any old images with the same id
 *
 * @param image The image to upload
 * @param userId The userid of the uploader
 * @returns A promise of the uploaded full path
 */
export const uploadLogoImage = async (
  image: File,
  userId: string
): Promise<string> => {
  // try to delete the old logo if it is applicable
  await deleteLogo(userId).catch(() => console.info("No image to delete"));
  // upload the new one
  const logoRef = ref(storage, `logos/${userId}`);
  return uploadBytes(logoRef, image)
    .then((snapshot) => snapshot.ref.fullPath)
    .catch((exc) => {
      console.error("Error during logo upload", exc);
      return "";
    });
};

/**
 * API method to delete a logo
 *
 * @param userId The id of the user whose logo should be deleted
 */
export const deleteLogo = async (userId: string): Promise<void> => {
  const oldLogo = ref(storage, `logos/${userId}`);
  return deleteObject(oldLogo).catch((exc) => {
    console.error("Error during logo deletion", exc);
  });
};

/**
 * Helper to load url to logo of provider id for display
 * @param providerId
 * @returns path to Logo of given provider Id
 */
export const getLogoUrl = async (providerId: string): Promise<string> => {
  const storageRef: StorageReference = ref(storage, `logos/${providerId}`);
  const url = await getDownloadURL(storageRef).catch((exc) => {
    console.error("Error during logo download", exc);
    return "";
  });
  return url;
};

/**
 * Fetches logo from temp storage and saves it for provider in persistent storage
 * @param logoName name of logo in temp storage
 * @param providerId id of provider for saving in persistent storage
 * @returns file for provider
 */
export const getTempLogoAndUploadForProvider = async (
  logoName: string,
  providerId: string
): Promise<File | undefined> => {
  const storageRef: StorageReference = ref(tempStorage, `logos/${logoName}`);
  const url: string | undefined = await getDownloadURL(storageRef).catch(
    (exc) => {
      console.error("Error during logo download", exc);
      return undefined;
    }
  );
  if (url)
    return fetch(url).then(async (response) => {
      let blob = await response.blob();
      let file = new File([blob], "provider_logo");
      uploadLogoImage(file, providerId);
      return file;
    });
  return;
};

/**
 * Updates Provider with private Information on Database
 * @param provider
 * @returns true, if update was successful
 */
export const updateProvider = async (provider: Provider): Promise<boolean> => {
  try {
    const { privateInformation, ...publicInformation } = provider;
    const q = query(
      collection(db, userDatabase),
      where("uid", "==", provider.uid)
    );
    const allDocs = await getDocs(q);
    if (allDocs.empty) {
      console.error("Provider not found on database!");
      return false;
    }
    const providerDoc = allDocs.docs[0];
    const providerRef = doc(db, userDatabase, providerDoc.id);
    await updateDoc(providerRef, publicInformation);
    const privateDoc = (
      await getDocs(collection(db, providerDoc.ref.path, "privateInformation"))
    )?.docs[0];
    if (!privateDoc) {
      console.error("PrivateInformation for Provider not found");
      return false;
    }
    const privateRef = doc(
      db,
      allDocs.docs[0].ref.path,
      "privateInformation",
      privateDoc.id
    );
    await updateDoc(privateRef, privateInformation);
    return true;
  } catch (err: any) {
    console.error("Error during updating provider", err);
    return false;
  }
};

/**
 * Helper to update Logo and provider
 * @param provider
 * @param logo
 * @returns true, if update of both provider and logo were successful
 */
export const updateProviderAndLogo = async (
  provider: Provider,
  logo?: File
): Promise<boolean> => {
  let success: boolean = true;
  success = await updateProvider(provider);
  if (!success) {
    generateNotification(i18next.t("notifications.updateError"), "warning");
    return false;
  }
  if (logo) success = await !!uploadLogoImage(logo, provider.uid);
  else deleteLogo(provider.uid!);
  if (!success) {
    generateNotification(i18next.t("notifications.updateError"), "warning");
    return false;
  }
  return true;
};

/**
 * API method to init a new provider and persist it on the
 * database.
 *
 * @param newProvider The new provider instance
 * @param logoImage The uploaded logo image
 * @param password The user entered password
 * @returns true if successful, false otherwise
 */
export const registerNewProvider = async (
  newProvider: Provider,
  password: string
): Promise<boolean> => {
  try {
    const user: User | undefined = await createAuthUser(
      newProvider.privateInformation!.email,
      password
    );
    if (!user) return false;
    const { privateInformation, ...generalInformation } = newProvider;
    await setDoc(doc(db, userDatabase, user.uid), {
      ...generalInformation,
      uid: user.uid,
    } as Provider);
    const userPrivateRef = collection(
      db,
      userDatabase,
      user.uid,
      "privateInformation"
    );
    await addDoc(userPrivateRef, {
      ...privateInformation,
      authProvider: "local",
    } as PrivateProviderInformation);
    return true;
  } catch (err: any) {
    console.error("Error during provider creation", err);
    return false;
  }
};

/**
 * Creates an user in auth service and generates notifications according to errors
 * @param email
 * @param password
 * @returns the new user user when creation was successful
 */
const createAuthUser = async (
  email: string,
  password: string
): Promise<User | undefined> => {
  try {
    const newEntry: UserCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    return newEntry.user;
  } catch (err: any) {
    console.error("Error during user creation", err);
    const errorMessage: string = (err as FirebaseError).message;
    if (errorMessage.includes("auth/email-already-in-use"))
      generateNotification(
        i18next.t("notifications.emailAlreadyExists"),
        "info"
      );
    else if (errorMessage.includes("auth/weak-password"))
      generateNotification(i18next.t("notifications.invalidPassword"), "info");
    else
      generateNotification(i18next.t("notifications.genericError"), "warning");
    return undefined;
  }
};

/**
 * API method to load many providers identified by their uid
 *
 * @param uids The uids of the provider to load
 * @returns The list of found provider
 */
export const loadManyProviderByUids = async (
  uids: string[]
): Promise<Provider[]> => {
  const providerQuery: Query<DocumentData> = query(
    collection(db, userDatabase),
    where("uid", "in", uids)
  );
  const targetArray: Provider[] = [];
  return getDocs(providerQuery).then((resp) => {
    if (resp.empty) return [];
    resp.forEach((provider) => targetArray.push(provider.data() as Provider));
    return targetArray;
  });
};

/**
 * Updates Stempler with private Information on Database
 * @param stempler
 * @returns if update was successful
 */
export const updateStempler = async (stempler: Stempler): Promise<boolean> => {
  try {
    const { privateInformation, ...publicInformation } = stempler;
    const q = query(
      collection(db, userDatabase),
      where("uid", "==", stempler.uid)
    );
    const allDocs = await getDocs(q);
    if (allDocs.empty) {
      console.error("Stempler not found on database!");
      return false;
    }
    const stemplerDoc = allDocs.docs[0];
    const stemplerRef = doc(db, userDatabase, stemplerDoc.id);
    await updateDoc(stemplerRef, publicInformation);
    const privateDoc = (
      await getDocs(collection(db, stemplerDoc.ref.path, "privateInformation"))
    )?.docs[0];
    if (!privateDoc) {
      console.error("PrivateInformation for Stempler not found");
      return false;
    }
    const privateRef = doc(
      db,
      allDocs.docs[0].ref.path,
      "privateInformation",
      privateDoc.id
    );
    await updateDoc(privateRef, privateInformation);
    return true;
  } catch (err: any) {
    console.error("Error during updating stempler", err);
    return false;
  }
};

/**
 * Util method to create a new DB entry. The cloud functions take care of the actual deletion
 * @param stemplerId The uid of the currently logged in user
 * @returns Promise of void
 */
export const addStemplerToDeleteDb = async (
  stemplerId: string
): Promise<void> => {
  return await addDoc(collection(db, userDeleteDatabase), {
    createDate: Timestamp.now(),
    userUid: stemplerId,
    type: UserType.STEMPLER, // currently only stempler can delete their profile this way
  }).then(() => {});
};

/**
 * Updates the theme for a user
 * @param user
 * @param theme theme user should have
 * @returns true if update was successful
 */
export const updateTheme = async (
  user: AppUser,
  theme: Theme
): Promise<boolean> => {
  try {
    const q = query(collection(db, userDatabase), where("uid", "==", user.uid));
    const allDocs = await getDocs(q);
    if (allDocs.empty) {
      console.error("User not found on database!");
      return false;
    }
    const userDoc = allDocs.docs[0];
    const userRef = doc(db, userDatabase, userDoc.id);
    await updateDoc(userRef, { ...userDoc.data(), theme: theme });
    return true;
  } catch (err: any) {
    generateNotification(i18next.t("notifications.feedbackError"), "warning");
    console.error("Error during updating theme for User", err);
    return false;
  }
};

import { PickerOption, Theme } from "stempl-component-library";
import { ReactComponent as DarkIcon } from "../../assets/icons/dark.svg";
import { ReactComponent as AutoIcon } from "../../assets/icons/dark_light.svg";
import { ReactComponent as LightIcon } from "../../assets/icons/light.svg";
import { Provider, Stempler, User, UserType } from "./User.types";

/**
 * Helper method to get firstname of user depending on type
 * @param user
 * @returns firstname of given user
 * @tested
 */
export const getFirstnameForUser = (user: User): string => {
  if (user.type === UserType.STEMPLER) return (user as Stempler).name;
  else if (user.type === UserType.PROVIDER)
    return (user as Provider).privateInformation!.firstName;
  else return "";
};

/**
 * Builds PickerOptions with correct content for themes
 * @returns Picker options for Themes
 * @tested
 */
export const buildPickerOptionsForThemes = (): PickerOption[] => {
  const options: PickerOption[] = [];
  options.push({
    value: Theme.LIGHT,
    content: <LightIcon />,
  });
  options.push({
    value: Theme.AUTO,
    content: <AutoIcon />,
  });
  options.push({
    value: Theme.DARK,
    content: <DarkIcon />,
  });
  return options;
};

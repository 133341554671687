import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import {
  ButtonComponent,
  LayoutComponent,
  PopupComponent,
  Theme,
} from "stempl-component-library";
import { ReactComponent as HomeIcon } from "../assets/icons/home.svg";
import { ReactComponent as LogoutIcon } from "../assets/icons/logout.svg";
import { ReactComponent as FeedbackIcon } from "../assets/icons/thumbs-up.svg";
import { ReactComponent as SettingsIcon } from "../assets/icons/settings.svg";
import { auth } from "../firebase";
import { useUser } from "../hooks/useUser";
import { logout } from "../utils/FirebaseUtils";
import { Provider, User, UserType } from "../utils/user/User.types";

/**
 * This is the user app context
 */
export const UserContext = React.createContext<{
  user?: User;
  updateUser(): Promise<void>;
  theme?: Theme;
  setTheme(theme: Theme): void;
}>({
  user: undefined,
  updateUser: () => Promise.resolve(),
  setTheme: () => {},
});

const PrivateLayoutWrapper: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { user, loading, updateUser } = useUser();
  const [showLogoutPopup, toggleShowLogoutPopup] = useState<boolean>(false);
  const { t } = useTranslation();
  const [theme, setTheme] = useState<Theme>(Theme.AUTO);

  // wait for everything to be loaded and decide about a redirect
  useEffect(() => {
    if (!loading && !user) navigate("/login");

    if (
      user &&
      user.type === UserType.PROVIDER &&
      !(user as Provider).registerFinished
    )
      navigate("/register");
    else if (
      user &&
      user.privateInformation?.authProvider === "local" &&
      !auth.currentUser?.emailVerified &&
      window?.location.pathname !== "/register" &&
      window?.location.pathname !== "/register/cancel" &&
      window?.location.pathname !== "/success"
    )
      navigate("/verify");
    setTheme(() => user?.theme);
    // eslint-disable-next-line
  }, [user, loading, window?.location.pathname]);

  return (
    <UserContext.Provider
      value={{
        user: user,
        updateUser: () => updateUser(),
        theme: user?.theme,
        setTheme: setTheme,
      }}
    >
      <LayoutComponent
        isLoading={!user}
        menuIcons={[
          <HomeIcon onClick={() => navigate("/")} />,
          <SettingsIcon onClick={() => navigate("/settings")} />,
          <LogoutIcon
            className="logout-icon"
            onClick={() => toggleShowLogoutPopup(true)}
          />,
          <FeedbackIcon onClick={() => navigate("/feedback")} />,
        ]}
        theme={theme}
      >
        <PopupComponent
          isOpen={showLogoutPopup}
          onClose={() => toggleShowLogoutPopup(false)}
        >
          <div>{t("general.logoutRequest")}</div>
          <br />
          <div className="button-wrapper">
            <ButtonComponent
              value={t("buttons.yes")}
              color="red"
              onClick={() => logout()}
            />
            <ButtonComponent
              value={t("buttons.no")}
              color="grey"
              onClick={() => toggleShowLogoutPopup(false)}
            />
          </div>
        </PopupComponent>
        <Outlet />
      </LayoutComponent>
    </UserContext.Provider>
  );
};

export default PrivateLayoutWrapper;

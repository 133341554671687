import { getApp } from "firebase/app";
import {
  addDoc,
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { generateNotification } from "stempl-component-library";
import { db } from "../firebase";
import i18n from "../i18n";

/**
 * The database reference for user (provider and stempler)
 */
const userDatabase: string = process.env.REACT_APP_USER_DATABASE!;

/**
 * This is the subcollection the stripe hook collects the session information
 */
const checkoutSubCollection: string =
  process.env.REACT_APP_CHECKOUT_SUBCOLLECTION!;

/**
 * This is the tax identifier on the stripe side
 */
const subscriptionTaxDE: string =
  process.env.REACT_APP_STRIPE_SUBSCRIPTION_TAX!;

/**
 * Stripe helper to generate a payment session and to start the
 * plan subscription
 *
 * @param uid The uid of the new provider
 */
export const startCheckout = async (
  uid: string,
  hideLoading: Function,
  isDiscounted: boolean,
  isEnteredLate?: boolean
): Promise<void> => {
  const subscriptionPrice: string =
    process.env.REACT_APP_STRIPE_SUBSCRIPTION_PRICE!;
  const q = query(collection(db, userDatabase), where("uid", "==", uid));
  const allDocs = await getDocs(q);
  if (allDocs.empty) {
    console.error("Provider not found on database!");
    generateNotification(i18n.t("notifications.genericError"), "warning");
    return;
  }
  const providerDoc = allDocs.docs[0];
  const docRef = await addDoc(
    collection(db, userDatabase, providerDoc.id, checkoutSubCollection),
    {
      uid: uid,
      price: subscriptionPrice,
      success_url: `${window?.location.origin}/success`,
      trial_from_plan: isDiscounted,
      tax_rates: [subscriptionTaxDE],
      cancel_url: isEnteredLate
        ? window?.location.origin
        : `${window?.location.origin}/register/cancel`,
    }
  );
  onSnapshot(docRef, (snap: any) => {
    const { error, url } = snap.data();
    if (error) {
      hideLoading();
      generateNotification(i18n.t("notifications.stripeError"), "warning");
    }
    if (url) {
      window?.location.assign(url);
    }
  });
};

/**
 * Stripe helper to open the customer portal of the currently logged in user
 */
export const openCustomerPortal = (): void => {
  const functions = getFunctions(getApp(), "europe-west3");
  const stripeFunction = httpsCallable(
    functions,
    "ext-firestore-stripe-payments-createPortalLink"
  );
  stripeFunction({
    returnUrl: window?.location.origin,
    locale: "auto",
  }).then((result) => {
    const data: any = result.data;
    window?.location.assign(data.url);
  });
};

import { t } from "i18next";
import { generateNotification } from "stempl-component-library";
import { CardComponentProps } from "stempl-component-library/build/CardComponent/CardComponent.types";
import i18n from "../../i18n";
import { getIconForBranche } from "../user/provider/Provider.utils";
import { Branche, Provider } from "../user/User.types";
import { generateNewStempl, StemplCard } from "./Stempl.types";

/**
 * Util to check if a stemplcard has enough collected stempl to be activated
 *
 * @param card The card to check
 * @returns true if the collected stempl are enough to activate
 * @tested
 */
export const ceckIfCardCanBeActivated = (card: StemplCard): boolean => {
  return card.stempls.length === card.targetAmount;
};

/**
 * Util to extract the provider id from the given scanned string. In case of
 * error this method also generates a notification
 *
 * @param qrUrl The scanned value
 * @returns The extracted provider id or undefined in case the QR code was invalid
 * @tested
 */
export const extractProviderIdFromQrUrl = (
  qrUrl: string
): string | undefined => {
  const urlParts: string[] = qrUrl.split("/");
  // check for the general format
  if (urlParts.length !== 5 || urlParts[2] !== process.env.REACT_APP_URL) {
    generateNotification(i18n.t("notifications.invalidQrCode"), "warning");
    return undefined;
  }
  return urlParts[4];
};

/**
 * Util method to transform the card objects to CardComponentProps.
 *
 * @param loadedCards The cards of the currently logged in stempler,
 * @param scanAction action to perform when clicked on + of card
 * @param imageMap Map that contains all needed images with corresponding providerId as key
 * @returns The CardComponentProps needed to display the carousel
 * @tested
 */
export const transformCardsToDisplayCards = (
  loadedCards: StemplCard[],
  scanAction: () => void = () => {},
  imageMap: Map<string, string>
): CardComponentProps[] => {
  const resultList: CardComponentProps[] = [];
  loadedCards.forEach((card) => {
    if (!card.cardIsFull) {
      resultList.push(
        transformCardToDisplayCard(
          card,
          scanAction,
          loadedCards.filter(
            (currentCard) => currentCard.providerId === card.providerId
          ).length - 1,
          imageMap.get(card.providerId)
        )
      );
    }
  });
  return resultList;
};

/**
 * Util method to transform a card object to CardComponentProps.
 *
 * @param loadedCard The card to transform into Props
 * @param scanAction action to perform when clicked on + of cards
 * @param amountFulfilledCards Optional, amount of fulfilled cards this user has for corresponding provider
 * @param imageUrl Optional, URL of provider Logo
 * @returns The CardComponentProps needed to display in CardComponent
 * @tested
 */
export const transformCardToDisplayCard = (
  loadedCard: StemplCard,
  scanAction: () => void = () => {},
  amountFulfilledCards?: number,
  imageUrl?: string
): CardComponentProps => {
  return {
    currentStempl: loadedCard.stempls.length,
    displayName:
      loadedCard.displayName ||
      i18n.t("pages.dashboard.stempler.providerNotFound"),
    icon: getIconForBranche(loadedCard.branche || Branche.OTHER),
    bgColor: loadedCard.color,
    maxStempl: loadedCard.targetAmount,
    logoPath: imageUrl,
    amountFulfilledCards: amountFulfilledCards || 0,
    rewardText: t("pages.dashboard.stempler.fulfillPreview", {
      replace: { discount: loadedCard.cardDiscount },
    }),
    onPlusClick: scanAction,
  };
};

/**
 * Util to map additional provider information onto the stemplcards
 *
 * @param stemplCards The loaded cards of the currently logged in Stempler
 * @param provider The loaded provider which are needed for the cards
 * @tested
 */
export const addProviderInformationToStemplCard = (
  stemplCards: StemplCard[],
  provider: Provider[]
): void => {
  stemplCards.forEach((card) => {
    const cardProvider: Provider | undefined = provider.find(
      (prov) => prov.uid === card.providerId
    );
    if (!cardProvider) return;
    card.displayName = cardProvider.displayName;
    card.branche = cardProvider.branche;
    card.color = cardProvider.color;
    card.cardDiscount = cardProvider.cardDiscount;
  });
};

/**
 * Helper that manipulates given stemplCards by updating given cardToUpdate
 * @param stemplCards
 * @param cardToUpdate
 * @tested
 */
export const updateStemplCardInArray = (
  stemplCards: StemplCard[],
  cardToUpdate: StemplCard
): boolean => {
  const index: number = stemplCards.findIndex(
    (card) => card.providerId === cardToUpdate.providerId && !card.cardIsFull
  );
  if (index === -1) return false;
  //transfer provider information
  cardToUpdate.displayName = stemplCards[index].displayName;
  cardToUpdate.branche = stemplCards[index].branche;
  cardToUpdate.color = stemplCards[index].color;
  //old card is full after adding new stempl just now and new one has to be added
  if (
    stemplCards[index].stempls.length + 1 ===
    stemplCards[index].targetAmount
  ) {
    //update old card so it doesn't appear in list
    stemplCards[index] = {
      ...stemplCards[index],
      stempls: [...stemplCards[index].stempls, generateNewStempl()],
      cardIsFull: true,
    };
    stemplCards.push(cardToUpdate);
  } else stemplCards[index] = cardToUpdate;
  return true;
};

/**
 * Helper to determine latest date of a STempl in this card
 * @param card
 * @returns latest date a stempl was made in this card, undefined when card has no stempls
 * @tested
 */
export const getLatestStemplDate = (card: StemplCard): Date | undefined => {
  if (card.stempls.length === 0) return undefined;
  let cardForSorting: StemplCard = { ...card };
  cardForSorting.stempls.sort(
    (stemplOne, stemplTwo) =>
      stemplTwo.createDate.toMillis() - stemplOne.createDate.toMillis()
  );
  return cardForSorting.stempls[0].createDate.toDate();
};

/**
 * Helper to map HexColors to css classes
 * @param color color as hexCode
 * @returns correct className for given color of stempl palette
 * @tested
 */
export const getClassForColor = (color: string): string => {
  switch (color) {
    case "#a2be46":
      return "lightgreen";
    case "#71b87c":
      return "green";
    case "#4faddb":
      return "blue";
    case "#eda93d":
      return "orange";
    case "#eb576d":
      return "red";
    case "#121212":
      return "darkgrey";
    case "#c292de":
      return "purple";
    case "#ebebeb":
      return "grey";
    case "#ffce00":
      return "yellow";
    default:
      return "";
  }
};

import { FirebaseError } from "firebase/app";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Params, useNavigate, useParams } from "react-router-dom";
import {
  BoxComponent,
  BoxTabs,
  ButtonComponent,
  generateNotification,
  LayoutComponent,
} from "stempl-component-library";
import { ReactComponent as GoogleIcon } from "../assets/icons/google-icon.svg";
import LoginForm from "../components/LoginForm";
import ProviderRegisterForm from "../components/ProviderRegisterForm";
import StemplerRegisterForm from "../components/StemplerRegisterForm";
import { signInWithGoogle } from "../utils/FirebaseUtils";
import { providerAppliesForOneMonthFree } from "../utils/user/provider/Provider.utils";
import { Branche } from "../utils/user/User.types";

const Login: React.FC<{
  discountedRegister?: boolean;
}> = ({ discountedRegister = false }) => {
  const { t } = useTranslation();
  const {
    register,
    userType,
    displayName,
    branche,
    color,
    reward,
    logoName,
    discountKey,
  }: Params<string> = useParams();
  const [activeTab, setActiveTab] = useState<number>(
    discountedRegister ? 1 : 0
  );
  const navigate = useNavigate();
  const [showStemplerRegister, toggleShowStemplerRegister] =
    useState<boolean>(false);
  const [showProviderRegister, toggleShowProviderRegister] =
    useState<boolean>(false);
  const [isLoading, toggleIsLoading] = useState<boolean>(false);
  const [isActualDisounted, toggleActualDiscounted] = useState<boolean>(false);

  //switches to register for Provider automatically when params are set
  useEffect(() => {
    if (register && userType === "STEMPLER") toggleShowStemplerRegister(true);
    else if (register || displayName) {
      toggleShowProviderRegister(true);
      setActiveTab(1);
    }
  }, [register, displayName, userType]);

  // checks if the page was opened via a discount link and set
  // the according variables
  useEffect(() => {
    if (!discountedRegister) return;
    if (!discountKey) return;
    if (providerAppliesForOneMonthFree(discountKey)) {
      toggleActualDiscounted(true);
      toggleShowProviderRegister(true);
    }
    //eslint-disable-next-line
  }, [discountedRegister]);

  const loginTabs: BoxTabs[] = [
    {
      index: 0,
      title: t("general.stempler"),
      content: (
        <div>
          {showStemplerRegister ? <StemplerRegisterForm /> : <LoginForm />}
        </div>
      ),
    },
    {
      index: 1,
      title: t("general.provider"),
      content: (
        <div>
          {showProviderRegister ? (
            <ProviderRegisterForm
              branche={branche as Branche}
              color={color}
              displayName={displayName}
              logoName={logoName}
              reward={reward}
              skipCardDesign={!!displayName}
              discountedRegister={isActualDisounted}
            />
          ) : (
            <LoginForm />
          )}
        </div>
      ),
    },
  ];

  return (
    <LayoutComponent>
      <div className="login-page">
        <BoxComponent
          tabs={loginTabs}
          currentTab={activeTab}
          onTabChange={setActiveTab}
        />
        <BoxComponent>
          <div className="login-page__register-wrapper">
            <b>
              {t(
                `pages.login.${
                  showStemplerRegister || showProviderRegister
                    ? "titleBackToLogin"
                    : "titleNew"
                }`
              )}
            </b>
            <p>
              {t(
                `pages.login.${
                  showStemplerRegister || showProviderRegister
                    ? "contentBackToLogin"
                    : "contentNew"
                }`
              )}
            </p>
            <div
              className={[
                "login-page__register-buttons",
                activeTab || showStemplerRegister ? "single-button" : "",
              ].join(" ")}
            >
              {(activeTab === 0 && showStemplerRegister) ||
              (activeTab === 1 && showProviderRegister) ? (
                <ButtonComponent
                  value={t("buttons.login")}
                  onClick={() => {
                    activeTab === 0
                      ? toggleShowStemplerRegister(false)
                      : toggleShowProviderRegister(false);
                  }}
                />
              ) : (
                <ButtonComponent
                  value={t("buttons.register")}
                  onClick={() =>
                    activeTab === 0
                      ? toggleShowStemplerRegister(true)
                      : toggleShowProviderRegister(true)
                  }
                />
              )}
              {activeTab === 0 && !showStemplerRegister && (
                <ButtonComponent
                  value={
                    <div className="google-button">
                      <GoogleIcon />
                      <span>Google</span>
                    </div>
                  }
                  isLoading={isLoading}
                  color="red"
                  onClick={() => {
                    toggleIsLoading(true);
                    signInWithGoogle()
                      .then(() => navigate("/"))
                      .catch((error) => {
                        const errorMessage: string = (error as FirebaseError)
                          .message;
                        if (!errorMessage.includes("auth/popup-closed-by-user"))
                          generateNotification(
                            t("notifications.genericError"),
                            "warning"
                          );
                        toggleIsLoading(false);
                      });
                  }}
                />
              )}
            </div>
          </div>
        </BoxComponent>
      </div>
    </LayoutComponent>
  );
};
export default Login;

import React from "react";
import { useTranslation } from "react-i18next";
import PWAPrompt from "react-ios-pwa-prompt";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ErrorOccured from "./components/ErrorOccured";
import PrivateLayoutWrapper from "./components/PrivateLayoutWrapper";
import SettingsMenu from "./pages/SettingsMenu";
import Dashboard from "./pages/Dashboard";
import FeedbackPage from "./pages/FeedbackPage";
import Login from "./pages/Login";
import PaymentCancel from "./pages/PaymentCancel";
import PaymentSuccess from "./pages/PaymentSuccess";
import ProviderRegister from "./pages/ProviderRegister";
import QRDisplay from "./pages/QRDisplay";
import VerificationPage from "./pages/VerificationPage";
import VerifyPage from "./pages/VerifyPage";
import withClearCache from "./ClearCache";

const App: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/login/:register/:userType" element={<Login />} />
        <Route
          path="/login/:displayName/:branche/:color/:reward"
          element={<Login />}
        />
        <Route
          path="/login/:displayName/:branche/:color/:reward/:logoName"
          element={<Login />}
        />
        <Route
          path="/qrcode/:displayName/:providerId/:amount"
          element={<QRDisplay />}
        />
        <Route
          path="/register/discounted/:discountKey"
          element={<Login discountedRegister />}
        />
        <Route path="/verification" element={<VerificationPage />} />
        <Route path="/" element={<PrivateLayoutWrapper />}>
          <Route index element={<Dashboard />} />
          <Route path="/verify" element={<VerifyPage />} />
          <Route path="/success" element={<PaymentSuccess />} />
          <Route path="/settings" element={<SettingsMenu />} />
          <Route path="/register" element={<ProviderRegister />} />
          <Route path="/register/cancel" element={<PaymentCancel />} />
          <Route path="/feedback" element={<FeedbackPage />} />
        </Route>
        <Route path="/error" element={<ErrorOccured />} />
      </Routes>
      <PWAPrompt
        copyTitle={t("general.pwaInstall.title")}
        copyBody={t("general.pwaInstall.body")}
        copyShareButtonLabel={t("general.pwaInstall.shareText")}
        copyAddHomeButtonLabel={t("general.pwaInstall.addText")}
        copyClosePrompt={t("buttons.abort")}
        timesToShow={999}
      />
    </Router>
  );
};

const ClearCacheComponent = withClearCache(App);

export default ClearCacheComponent;

import { Timestamp } from "firebase/firestore";
import { User, UserType } from "../user/User.types";

/**
 * The feedback object wrapper for a feedback
 * an user can give optionally
 */
export interface Feedback {
  createDate: Timestamp;
  userId: string;
  rating: FeedbackRating;
  userType: UserType;
  feedback?: string;
  version: string;
}

/**
 * This value identifies the selected smiley value
 * of the feedback
 */
export enum FeedbackRating {
  NONE = "NONE",
  SAD = "SAD",
  NEUTRAL = "NEUTRAL",
  HAPPY = "HAPPY",
}

/**
 * Helper to generate a new feedback object for the currently
 * logged in user
 *
 * @param user the currently logged in user
 * @returns A new feedback object to be filled by the user
 */
export const createNewFeedbackForUser = (user: User): Feedback => ({
  createDate: Timestamp.now(),
  userId: user.uid,
  rating: FeedbackRating.NONE,
  userType: user.type,
  version: process.env.REACT_APP_VERSION || "No version found",
});

import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ButtonComponent } from "stempl-component-library";
import { UserContext } from "../components/PrivateLayoutWrapper";
import { auth } from "../firebase";
import { resendEmailVerification } from "../utils/FirebaseUtils";
import { getFirstnameForUser } from "../utils/user/User.utils";

const VerifyPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [isLoading, toggleLoading] = useState<boolean>(false);

  return (
    <>
      <h2>{t("pages.verify.pleaseVerifyMailFirst")}</h2>
      <br />
      <ButtonComponent
        className="wide-button"
        value={t("pages.verify.resendLink")}
        onClick={() =>
          resendEmailVerification(
            user!.privateInformation!.email,
            getFirstnameForUser(user!)
          )
        }
      />
      <ButtonComponent
        className="wide-button"
        isLoading={isLoading}
        value={t("pages.verify.tryAgain")}
        onClick={() => {
          toggleLoading(true);
          auth.currentUser?.reload().then(() => {
            navigate("/");
            toggleLoading(false);
          });
        }}
      />
    </>
  );
};
export default VerifyPage;

import { addDoc, collection } from "firebase/firestore";
import { generateNotification } from "stempl-component-library";
import { db } from "../../firebase";
import i18n from "../../i18n";
import { Feedback } from "./Feedback.types";

const feedbackDatabase: string = process.env.REACT_APP_FEEDBACKS!;

/**
 * Helper to generate the feedback DB entry
 *
 * @param feedback The feedback to generate
 */
export const createNewFeedback = async (
  feedback: Feedback
): Promise<boolean> => {
  return addDoc(collection(db, feedbackDatabase), feedback)
    .then(() => {
      generateNotification(i18n.t("notifications.feedbackSuccess"), "success");
      return true;
    })
    .catch((exc) => {
      console.error("Error during feedback generation", exc);
      generateNotification(i18n.t("notifications.feedbackError"), "warning");
      return false;
    });
};

import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ButtonComponent } from "stempl-component-library";
import { startCheckout } from "../utils/StripeUtils";
import { Provider } from "../utils/user/User.types";

const PaymentCancel: React.FC<{}> = () => {
  const [buttonsAreLoading, toggleButtonLoading] = useState<boolean>(false);
  const [provider, setProvider] = useState<Provider>();
  const cookieName: string = process.env.REACT_APP_PROVIDER_REGISTER_COOKIE!;
  const [cookies] = useCookies([cookieName]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  // load the provider saved in the cookie
  useEffect(() => {
    navigator?.vibrate?.([150, 150, 150]); // show the user what he did! 😈
    setProvider(cookies[cookieName] as Provider);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="payment-cancel__wrapper">
      <h2>{t("pages.paymentCancel.title")}</h2>
      <p>{t("pages.paymentCancel.paragraph1")}</p>
      <p>{t("pages.paymentCancel.paragraph2")}</p>
      <br />
      <ButtonComponent
        className="wide-button"
        value={t("pages.paymentCancel.enterCta")}
        onClick={() => {
          toggleButtonLoading(true);
          provider &&
            startCheckout(
              provider.uid,
              () => toggleButtonLoading(false),
              provider.privateInformation?.registerIsDiscounted || false
            );
        }}
        isLoading={buttonsAreLoading}
      />
      <ButtonComponent
        className="wide-button"
        value={t("pages.paymentCancel.waitCta")}
        onClick={() => navigate("/")}
        isLoading={buttonsAreLoading}
      />
    </div>
  );
};

export default PaymentCancel;

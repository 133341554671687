import { FirebaseError } from "firebase/app";
import {
  applyActionCode,
  confirmPasswordReset,
  verifyPasswordResetCode,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  ButtonComponent,
  generateNotification,
  InputComponent,
  LayoutComponent,
} from "stempl-component-library";
import { ReactComponent as EyeIconOff } from "../assets/icons/visibility-off.svg";
import { ReactComponent as EyeIcon } from "../assets/icons/visibility.svg";
import { auth } from "../firebase";

const VerificationPage: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("");
  const [showPassword, toggleShowPassword] = useState<boolean>(false);
  const [isLoading, toggleIsLoading] = useState<boolean>(false);
  const [actionCode, setActionCode] = useState<string>("");
  const [mode, setMode] = useState<string>("");
  const [serchParams] = useSearchParams();
  const [actionCodeValid, toggleActionCodeValid] = useState<boolean>(false);
  const [success, toggleSuccess] = useState<boolean>(false);

  //initially performs e mail action and sets states according to params
  useEffect(() => {
    performActionAndSetStates();
    // eslint-disable-next-line
  }, []);

  /**
   * sets states according to searchParams and performs E-Mail Action on firebase, when action code is still valid
   */
  const performActionAndSetStates = async () => {
    const mode: string = serchParams.get("mode") || "invalid";
    const actionCode: string = serchParams.get("oobCode") || "";
    switch (mode) {
      case "resetPassword":
        await verifyPasswordResetCode(auth, actionCode)
          .then(() => toggleActionCodeValid(true))
          .catch((error) => console.error(error));
        break;
      case "verifyEmail":
        await applyActionCode(auth, actionCode)
          .then(() => toggleSuccess(true))
          .catch((error) => console.error(error));
        break;
    }
    setMode(mode);
    setActionCode(actionCode);
  };

  /**
   * if user is logged in, we refresh the auth user, then redirect to dashboard
   */
  const handleContinueRegister = () => {
    if (auth.currentUser) {
      toggleIsLoading(true);
      auth.currentUser
        .reload()
        .then(() => navigate("/"))
        .catch(() => toggleIsLoading(false));
    } else navigate("/");
  };

  /**
   * saves new password, when action code still valid and generates notification when password is too weak
   */
  const saveNewPassword = (): void => {
    confirmPasswordReset(auth, actionCode, newPassword)
      .then(() => toggleSuccess(true))
      .catch((error) => {
        const errorMessage: string = (error as FirebaseError).message;
        if (errorMessage.includes("auth/weak-password"))
          generateNotification(t("notifications.invalidPassword"), "warning");
        else toggleActionCodeValid(false);
      });
  };

  /**
   * Helper method to get correct header text according to states
   * @returns text to display
   */
  const getActionText = (): string => {
    if (mode === "verifyEmail" && success)
      return t("pages.verification.emailVerified");
    if (mode === "resetPassword") {
      if (success) return t("pages.verification.passwordChanged");
      if (actionCodeValid) return t("pages.verification.resetPassword");
    }
    return t("pages.verification.emailActionError");
  };

  return (
    <LayoutComponent isLoading={!mode}>
      <h2>{getActionText()}</h2>
      <br />
      {mode === "resetPassword" && actionCodeValid && !success && (
        <form
          onSubmit={(evt) => {
            evt.preventDefault();
            if (newPassword === newPasswordConfirm) saveNewPassword();
            else
              generateNotification(
                t("notifications.NotSamePasswords", "warning")
              );
          }}
        >
          <div className="login-form--password">
            <InputComponent
              value={newPassword}
              label={t("general.password")}
              type={showPassword ? "text" : "password"}
              onChange={setNewPassword}
              required
            />
            <div className="login-form--password--icon">
              {showPassword ? (
                <EyeIconOff onClick={() => toggleShowPassword(false)} />
              ) : (
                <EyeIcon onClick={() => toggleShowPassword(true)} />
              )}
            </div>
          </div>
          <div className="login-form--password-confirm">
            <InputComponent
              value={newPasswordConfirm}
              label={t("general.passwordConfirm")}
              type={showPassword ? "text" : "password"}
              onChange={setNewPasswordConfirm}
              required
            />
          </div>
          <br />
          <ButtonComponent
            className="wide-button"
            type="submit"
            value={t("pages.verification.changePassword")}
          />
        </form>
      )}
      {success && (
        <ButtonComponent
          className="wide-button"
          isLoading={isLoading}
          value={t("pages.verification.goToDashboard")}
          onClick={handleContinueRegister}
        />
      )}
    </LayoutComponent>
  );
};
export default VerificationPage;

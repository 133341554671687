import React, { useContext } from "react";
import { LoaderComponent } from "stempl-component-library";
import { UserContext } from "../components/PrivateLayoutWrapper";
import ProviderDashboard from "../components/ProviderDashboard";
import StemplerDashboard from "../components/StemplerDashboard";
import { Provider, Stempler, UserType } from "../utils/user/User.types";

const Dashboard: React.FC<{}> = () => {
  const { user } = useContext(UserContext);

  return user ? (
    user.type === UserType.PROVIDER ? (
      <ProviderDashboard provider={user as Provider} />
    ) : (
      <StemplerDashboard stempler={user as Stempler} />
    )
  ) : (
    <LoaderComponent />
  );
};
export default Dashboard;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Params, useParams } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/logo.svg";
import LogoQR from "../assets/Logo_QR.png";
import { QRCode } from "react-qrcode-logo";
import { getQrContentForProviderId } from "../utils/user/provider/Provider.utils";
import { ButtonComponent, LoaderComponent } from "stempl-component-library";

const QRDisplay: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { displayName, providerId, amount }: Params<string> = useParams();
  const [qrCodes, setQrCodes] = useState<JSX.Element[]>([]);
  const firefoxMobileUsed: boolean =
    navigator.userAgent.includes("Firefox") &&
    navigator.userAgent.includes("Mobile");

  //builds qr codes in given amount with given providerId
  useEffect(() => {
    if (!amount || !providerId) return;
    const amountAsNumber: number = parseInt(amount);
    const generatedQrCodes: JSX.Element[] = [];
    for (let index = 0; index < amountAsNumber; index++) {
      generatedQrCodes.push(
        <div
          className="qr-display--qr-code-wrapper"
          style={{ width: `${100 / Math.ceil(Math.sqrt(amountAsNumber))}%` }}
        >
          <QRCode
            value={getQrContentForProviderId(providerId!)}
            logoImage={LogoQR}
            //we scale down canvas with style in wrapper, but size is the "max resolution",
            // so we set that value relatively high
            size={1000}
            logoHeight={300}
            logoWidth={300}
            eyeRadius={10}
            quietZone={1}
          />
        </div>
      );
    }
    setQrCodes(generatedQrCodes);
  }, [amount, providerId]);

  return (
    <div className="qr-display">
      <div className="qr-display--info">
        <div className="qr-display--info--logo">
          <Logo />
        </div>
        <p>{t("pages.qrDisplay.qrExplanation")}</p>
      </div>
      <div className="qr-display--heading">
        <div>{displayName}</div>
        <div className="qr-display--button-wrapper">
          <ButtonComponent
            value={t("buttons.back")}
            onClick={() => window?.close()}
          />
          {firefoxMobileUsed || (
            <ButtonComponent
              value={t("buttons.print")}
              onClick={() => window?.print()}
            />
          )}
        </div>
      </div>
      <div className="qr-display--qr-codes">
        {qrCodes.length > 0 ? (
          qrCodes.map((qrElem) => qrElem)
        ) : (
          <LoaderComponent />
        )}
      </div>
    </div>
  );
};
export default QRDisplay;

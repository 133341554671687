import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  ButtonComponent,
  CardComponent,
  InputComponent,
  LoaderComponent,
  PickerComponent,
  PopupComponent,
  UploadComponent,
} from "stempl-component-library";
import { ReactComponent as UploadIcon } from "../assets/icons/file-upload.svg";
import { ReactComponent as PenIcon } from "../assets/icons/pen.svg";
import { getClassForColor } from "../utils/stempl/Stempl.utils";
import {
  buildPickerOptionsForBranches,
  getIconForBranche,
  getPickerOptionWithLogo,
} from "../utils/user/provider/Provider.utils";
import {
  getLogoUrl,
  updateProvider,
  updateProviderAndLogo,
} from "../utils/user/User.firebase";
import { Branche, Provider, User } from "../utils/user/User.types";

interface CardSettingsProps {
  onBack(): void;
  user: User;
  setUser(user: User): void;
}

/**
 * Locally needed enum to determine which part of card settings
 * should be displayed
 */
enum CardStep {
  NAME,
  COLOR,
  BRANCHE,
  LOGO,
}

const CardSettings: React.FC<CardSettingsProps> = ({
  onBack,
  user,
  setUser,
}) => {
  const [provider, setProvider] = useState<Provider>();
  const [logoUrl, setLogoUrl] = useState<string>("");
  const [logo, setLogo] = useState<File>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const hiddenUploadRef = useRef<HTMLInputElement>(null);
  const [isLoadingSave, toggleLoadingSave] = useState<boolean>(false);
  const [hideLogo, toggleHideLogo] = useState<boolean>(false);
  const [cardStep, setCardStep] = useState<CardStep>();

  //initially sets provider from user and loads currently set Logo
  useEffect(() => {
    if (!user || !navigate) return;
    setProvider(user as Provider);
    getLogoUrl(user.uid).then(setLogoUrl);
  }, [user, navigate]);

  /**
   * Helper to handle the save button click
   */
  const handleSave = async (): Promise<void> => {
    toggleLoadingSave(true);
    let success: boolean = false;
    if (hideLogo) {
      setLogo(undefined);
      setLogoUrl("");
    }
    toggleHideLogo(false);
    //checking for logoUrl and logo to be both defined/undefined to perform change,
    //when logoUrl is defined but logo not, it means logoUrl was loaded only from storage and no change has occured
    if (!!logoUrl === !!logo)
      success = await updateProviderAndLogo(provider!, logo);
    else success = await updateProvider(provider!);
    if (success) {
      setUser(provider!);
      setCardStep(undefined);
    }
    toggleLoadingSave(false);
  };

  return provider ? (
    <>
      <div className="settings-menu-entry heading">
        <PenIcon className="settings-menu-icon" />
        <div>{t("pages.settings.card")}</div>
      </div>
      <div
        className="card-settings-entry"
        onClick={() => setCardStep(CardStep.NAME)}
      >
        <div className="card-settings-entry--label">
          {t("pages.settings.provider.displayName")}
        </div>
        <div className="card-settings-entry--value">
          {provider?.displayName}
        </div>
      </div>
      <div
        className="card-settings-entry"
        onClick={() => setCardStep(CardStep.COLOR)}
      >
        <div className="card-settings-entry--label">
          {t("pages.settings.provider.color")}
        </div>
        <div className="card-settings-entry--value">
          <div
            className={`card-settings-entry--value--color ${getClassForColor(
              provider!.color
            )}`}
          />
        </div>
      </div>
      <div
        className="card-settings-entry"
        onClick={() => setCardStep(CardStep.BRANCHE)}
      >
        <div className="card-settings-entry--label">
          {t("pages.settings.provider.branche")}
        </div>
        <div className="card-settings-entry--value">
          {getIconForBranche(provider!.branche)}
        </div>
      </div>
      <div
        className="card-settings-entry"
        onClick={() => setCardStep(CardStep.LOGO)}
      >
        <div className="card-settings-entry--label">
          {t("pages.settings.provider.logo")}
        </div>
        <div className="card-settings-entry--value">
          {logoUrl ? <img src={logoUrl} alt="Logo" /> : <UploadIcon />}
        </div>
      </div>
      <CardComponent
        currentStempl={4}
        displayName={provider!.displayName || ""}
        icon={getIconForBranche(provider!.branche)}
        bgColor={provider!.color}
        logoPath={logoUrl}
        onPlusClick={() => {}}
        rewardText={t("pages.dashboard.stempler.fulfillPreview", {
          replace: { discount: provider!.cardDiscount },
        })}
        flippable
      />
      <br />
      <br />
      <ButtonComponent value={t("buttons.back")} onClick={onBack} />
      <PopupComponent
        isOpen={cardStep !== undefined}
        onClose={() => setCardStep(undefined)}
      >
        <input
          hidden
          type="file"
          accept="image/*"
          onChange={(event) => {
            if (event.target.files && event.target.files.length > 0) {
              setLogo(event.target.files[0]);
              setLogoUrl(URL.createObjectURL(event.target.files[0]));
            }
          }}
          ref={hiddenUploadRef}
        />
        {cardStep === CardStep.NAME && (
          <InputComponent
            label={t("pages.settings.provider.displayName")}
            value={provider.displayName}
            onChange={(newValue) =>
              setProvider({ ...provider, displayName: newValue })
            }
          />
        )}
        {cardStep === CardStep.COLOR && (
          <PickerComponent
            label={t("pages.settings.provider.chooseColor")}
            useColorOptions
            setSelectedOption={(newColor) =>
              setProvider({ ...provider, color: newColor })
            }
            selectedOption={provider.color}
          />
        )}
        {cardStep === CardStep.BRANCHE && (
          <>
            <PickerComponent
              label={t("pages.settings.provider.chooseBranche")}
              options={buildPickerOptionsForBranches()}
              setSelectedOption={(branche) =>
                setProvider({
                  ...provider,
                  branche: branche as Branche,
                })
              }
              selectedOption={provider.branche}
            />
            <PickerComponent
              label={t("pages.settings.provider.noFittingBranche")}
              options={[getPickerOptionWithLogo()]}
              setSelectedOption={(branche) =>
                setProvider({
                  ...provider,
                  branche: branche as Branche,
                })
              }
              selectedOption={provider.branche}
            />
          </>
        )}

        {cardStep === CardStep.LOGO && (
          <>
            <div
              className="provider-register__upload"
              onClick={() => hiddenUploadRef?.current?.click()}
            >
              {logoUrl && !hideLogo ? (
                <img src={logoUrl} alt="Logo" />
              ) : (
                <UploadIcon />
              )}
            </div>
            <div className="button-wrapper">
              <UploadComponent
                buttonText={t("pages.settings.provider.uploadLogo")}
                addFiles={(files) => {
                  setLogoUrl(URL.createObjectURL(files[0]));
                  setLogo(files[0]);
                }}
              />
              <ButtonComponent
                color="grey"
                value={t("pages.settings.provider.removeLogo")}
                onClick={() => toggleHideLogo(true)}
              />
            </div>
            <div className="separator" />
          </>
        )}

        <div className="button-wrapper">
          <ButtonComponent
            value={t("buttons.save")}
            onClick={handleSave}
            isLoading={isLoadingSave}
          />
          <ButtonComponent
            color="grey"
            value={t("buttons.abort")}
            onClick={() => {
              setCardStep(undefined);
              setProvider(user as Provider);
              toggleHideLogo(false);
            }}
          />
        </div>
      </PopupComponent>
    </>
  ) : (
    <LoaderComponent />
  );
};
export default CardSettings;

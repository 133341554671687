import React, { useState, useEffect } from "react";
import packageJson from "../package.json";
import moment from "moment";
import { LoaderComponent } from "stempl-component-library";

export const refreshCacheAndReload = () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then((names) => {
      for (const name of names) {
        caches.delete(name);
      }
    });
  }
  // delete browser cache and hard reload
  window.location.reload(true);
};

const buildDateGreaterThan = (latestDate, currentDate) => {
  const momLatestDateTime = moment(latestDate);
  const momCurrentDateTime = moment(currentDate);

  if (momLatestDateTime.isAfter(momCurrentDateTime)) {
    console.info("NEW VERSION DETECTED - CLEARING CACHES!");
    return true;
  } else {
    return false;
  }
};

const withClearCache = (Component) => {
  function ClearCacheComponent(props) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

    useEffect(() => {
      // see: https://hacks.mozilla.org/2016/03/referrer-and-cache-control-apis-for-fetch/
      fetch("/meta.json", { cache: "reload" })
        .then((response) => response.json())
        .then((meta) => {
          const latestVersionDate = meta.buildDate;
          const currentVersionDate = packageJson.buildDate;

          const shouldForceRefresh = buildDateGreaterThan(
            latestVersionDate,
            currentVersionDate
          );
          if (shouldForceRefresh) {
            setIsLatestBuildDate(false);
            refreshCacheAndReload();
          } else {
            setIsLatestBuildDate(true);
          }
        });
    }, []);

    return (
      <React.Fragment>
        {isLatestBuildDate ? <Component {...props} /> : <LoaderComponent />}
      </React.Fragment>
    );
  }

  return ClearCacheComponent;
};

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format("DD-MM-YYY HH:MM");
  return buildDate;
};

export default withClearCache;

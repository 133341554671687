import { useContext, useEffect, useState } from "react";
import { ReactComponent as HappySmiley } from "../assets/icons/smiley_happy.svg";
import { ReactComponent as NeutralSmiley } from "../assets/icons/smiley_neutral.svg";
import { ReactComponent as SadSmiley } from "../assets/icons/smiley_sad.svg";
import {
  BoxComponent,
  ButtonComponent,
  InputComponent,
} from "stempl-component-library";
import { UserContext } from "../components/PrivateLayoutWrapper";
import {
  createNewFeedbackForUser,
  Feedback,
  FeedbackRating,
} from "../utils/feedback/Feedback.types";
import { useNavigate } from "react-router-dom";
import { createNewFeedback } from "../utils/feedback/Feedback.firebase";
import { useTranslation } from "react-i18next";

const FeedbackPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [feedback, setFeedback] = useState<Feedback>();

  // if the user is loaded create a new feedback object for him
  useEffect(() => {
    if (!user) return;
    setFeedback(createNewFeedbackForUser(user));
    //eslint-disable-next-line
  }, []);

  return (
    <div className="feedback-page__wrapper">
      <BoxComponent>
        <h1>{t("pages.feedback.title")}</h1>
        <div className="feedback-page__smiley-wrapper">
          <SadSmiley
            className={feedback?.rating === FeedbackRating.SAD ? "active" : ""}
            onClick={() =>
              setFeedback((currentFeedback) => ({
                ...currentFeedback!,
                rating: FeedbackRating.SAD,
              }))
            }
          />
          <NeutralSmiley
            className={
              feedback?.rating === FeedbackRating.NEUTRAL ? "active" : ""
            }
            onClick={() =>
              setFeedback((currentFeedback) => ({
                ...currentFeedback!,
                rating: FeedbackRating.NEUTRAL,
              }))
            }
          />
          <HappySmiley
            className={
              feedback?.rating === FeedbackRating.HAPPY ? "active" : ""
            }
            onClick={() =>
              setFeedback((currentFeedback) => ({
                ...currentFeedback!,
                rating: FeedbackRating.HAPPY,
              }))
            }
          />
        </div>
        <h2>{t("pages.feedback.additionals")}</h2>
        <InputComponent
          value={feedback?.feedback || ""}
          onChange={(value) =>
            setFeedback((currentFeedback) => ({
              ...currentFeedback!,
              feedback: value,
            }))
          }
        />
        <h2>{t("pages.feedback.thanks")}</h2>
        <div className="button-wrapper">
          <ButtonComponent
            value={t("buttons.back")}
            color="grey"
            onClick={() => navigate("/")}
          />
          <ButtonComponent
            value={t("buttons.send")}
            disabled={feedback?.rating === FeedbackRating.NONE}
            onClick={() =>
              createNewFeedback(feedback!).then(
                (success) => success && navigate("/")
              )
            }
          />
        </div>
      </BoxComponent>
    </div>
  );
};

export default FeedbackPage;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  ButtonComponent,
  DropdownComponent,
  InputComponent,
  PopupComponent,
} from "stempl-component-library";
import { logout } from "../utils/FirebaseUtils";
import {
  addStemplerToDeleteDb,
  updateStempler,
} from "../utils/user/User.firebase";
import {
  Language,
  PrivateUserInformation,
  Stempler,
  User,
} from "../utils/user/User.types";

interface StemplerSettingsProps {
  user: User;
  setUser(user: User): void;
  onBack(): void;
}

const StemplerSettings: React.FC<StemplerSettingsProps> = ({
  user,
  setUser,
  onBack,
}) => {
  const [stempler, setStempler] = useState<Stempler>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [showDeleteAccountPopup, toggleDeleteAccountPopup] =
    useState<boolean>(false);

  //initially sets stempler from user and loads currently set Logo
  useEffect(() => {
    if (!user || !navigate) return;
    setStempler(user as Stempler);
  }, [user, navigate]);

  /**
   * Helper to update privateInformation of stempler
   * @param privateInformation
   */
  const setPrivateInformation = (
    privateInformation: PrivateUserInformation
  ): void =>
    setStempler({ ...stempler!, privateInformation: privateInformation });

  /**
   * Helper to handle the save button click
   */
  const handleSave = (): void => {
    toggleLoading(true);
    updateStempler(stempler!).then((success) => {
      if (success) {
        setUser(stempler!);
        onBack();
      }
      toggleLoading(false);
    });
  };

  return (
    <>
      {stempler && (
        <>
          <InputComponent
            label={t("pages.settings.stempler.name")}
            value={stempler.name || ""}
            onChange={(newValue) =>
              setStempler({
                ...stempler,
                name: newValue,
              })
            }
          />
          <InputComponent
            label={t("pages.settings.email")}
            value={stempler.privateInformation?.email || ""}
            onChange={(newValue) =>
              setPrivateInformation({
                ...stempler.privateInformation!,
                email: newValue,
              })
            }
            type="email"
          />
          <DropdownComponent
            label={t("pages.settings.language")}
            selectedOption={stempler.language}
            options={Object.values(Language).map((lang) => ({
              label: t(`enums.language.${lang}`),
              value: lang,
            }))}
            onChange={(newValue) =>
              setStempler({
                ...stempler,
                language: newValue as Language,
              })
            }
          />
          <h2 className="centered-text">{t("pages.settings.dangerZone")}</h2>
          <ButtonComponent
            value={t("pages.settings.deleteAccount.button")}
            color="red"
            onClick={() => toggleDeleteAccountPopup(true)}
          />
          <PopupComponent
            isOpen={showDeleteAccountPopup}
            onClose={() => toggleDeleteAccountPopup(false)}
          >
            <div className="settings-page__delete-popup__wrapper">
              <h1>{t("pages.settings.deleteAccount.title")}</h1>
              <p>{t("pages.settings.deleteAccount.contentStempler")}</p>
              <ButtonComponent
                value={t("pages.settings.deleteAccount.stay")}
                onClick={() => toggleDeleteAccountPopup(false)}
              />
              <ButtonComponent
                value={t("pages.settings.deleteAccount.leaveStempler")}
                color="red"
                isLoading={isLoading}
                onClick={() =>
                  addStemplerToDeleteDb(stempler.uid!).then(() => logout())
                }
              />
            </div>
          </PopupComponent>
        </>
      )}
      <div className="button-wrapper">
        <ButtonComponent
          value={t("buttons.save")}
          onClick={handleSave}
          isLoading={isLoading}
        />
        <ButtonComponent
          color="grey"
          value={t("buttons.abort")}
          onClick={onBack}
        />
      </div>
    </>
  );
};
export default StemplerSettings;

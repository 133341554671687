import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  ButtonComponent,
  DropdownComponent,
  InputComponent,
  PopupComponent,
} from "stempl-component-library";
import { openCustomerPortal, startCheckout } from "../utils/StripeUtils";
import { getDaysLeftUntilTimeStamp } from "../utils/user/provider/Provider.utils";
import { updateProvider } from "../utils/user/User.firebase";
import {
  Language,
  PrivateProviderInformation,
  Provider,
  User,
} from "../utils/user/User.types";
interface ProviderSettingsProps {
  user: User;
  setUser(user: User): void;
  onBack(): void;
}

const ProviderSettings: React.FC<ProviderSettingsProps> = ({
  user,
  setUser,
  onBack,
}) => {
  const [provider, setProvider] = useState<Provider>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [isLoadingSave, toggleLoadingSave] = useState<boolean>(false);
  const [providerPaymentMissing, toggleProviderPaymentMissing] =
    useState<boolean>(false);
  const [showDeleteAccountPopup, toggleDeleteAccountPopup] =
    useState<boolean>(false);

  //initially sets provider from user and loads currently set Logo
  useEffect(() => {
    if (!user || !navigate) return;
    setProvider(user as Provider);
    toggleProviderPaymentMissing(
      !!(user as Provider).privateInformation?.daysUntilPaymentInformation
    );
  }, [user, navigate]);

  /**
   * Helper to update privateInformation of provider
   * @param privateInformation
   */
  const setPrivateInformation = (
    privateInformation: PrivateProviderInformation
  ): void =>
    setProvider({ ...provider!, privateInformation: privateInformation });

  /**
   * Helper to handle the save button click
   */
  const handleSave = async (): Promise<void> => {
    toggleLoadingSave(true);
    const success: boolean = await updateProvider(provider!);
    if (success) {
      setUser(provider!);
      onBack();
    }
    toggleLoading(false);
  };

  return (
    <>
      {provider && (
        <>
          <InputComponent
            label={t("pages.settings.provider.firstName")}
            value={provider.privateInformation?.firstName || ""}
            onChange={(newValue) =>
              setPrivateInformation({
                ...provider.privateInformation!,
                firstName: newValue,
              })
            }
          />
          <InputComponent
            label={t("pages.settings.provider.lastName")}
            value={provider.privateInformation?.lastName || ""}
            onChange={(newValue) =>
              setPrivateInformation({
                ...provider.privateInformation!,
                lastName: newValue,
              })
            }
          />
          <InputComponent
            label={t("pages.settings.provider.street")}
            value={provider.privateInformation?.street || ""}
            onChange={(newValue) =>
              setPrivateInformation({
                ...provider.privateInformation!,
                street: newValue,
              })
            }
          />
          <InputComponent
            label={t("pages.settings.provider.zip")}
            value={provider.privateInformation?.zip || ""}
            onChange={(newValue) =>
              setPrivateInformation({
                ...provider.privateInformation!,
                zip: newValue,
              })
            }
          />
          <InputComponent
            label={t("pages.settings.provider.city")}
            value={provider.privateInformation?.city || ""}
            onChange={(newValue) =>
              setPrivateInformation({
                ...provider.privateInformation!,
                city: newValue,
              })
            }
          />
          <InputComponent
            label={t("pages.settings.email")}
            value={provider.privateInformation?.email || ""}
            onChange={(newValue) =>
              setPrivateInformation({
                ...provider.privateInformation!,
                email: newValue,
              })
            }
            type="email"
          />
          <DropdownComponent
            label={t("pages.settings.language")}
            selectedOption={provider.language}
            options={Object.values(Language).map((lang) => ({
              label: t(`enums.language.${lang}`),
              value: lang,
            }))}
            onChange={(newValue) =>
              setProvider({
                ...provider,
                language: newValue as Language,
              })
            }
          />
          {providerPaymentMissing && (
            <p className="red-text">
              {t("pages.dashboard.provider.missingPaymentWarning", {
                replace: {
                  daysLeft: getDaysLeftUntilTimeStamp(
                    provider.privateInformation!.daysUntilPaymentInformation!
                  ),
                },
              })}
            </p>
          )}

          <h2 className="centered-text">{t("pages.settings.dangerZone")}</h2>
          <ButtonComponent
            isLoading={isLoading}
            value={t("pages.settings.provider.stripe")}
            onClick={() => {
              toggleLoading(true);
              providerPaymentMissing
                ? startCheckout(
                    provider.uid,
                    () => toggleLoading(false),
                    provider.privateInformation?.registerIsDiscounted || false,
                    true
                  )
                : openCustomerPortal();
            }}
          />
          {!!provider.deleteDate || (
            <ButtonComponent
              value={t("pages.settings.deleteAccount.button")}
              color="red"
              onClick={() => toggleDeleteAccountPopup(true)}
            />
          )}
          {provider.deleteDate && (
            <div className="deletion-text">
              {t("pages.settings.deleteAccount.deletionPlannedFor", {
                deleteDate: provider.deleteDate?.toDate().toLocaleDateString(),
              })}
            </div>
          )}
          <div className="separator" />
          <div className="button-wrapper">
            <ButtonComponent
              value={t("buttons.save")}
              onClick={handleSave}
              isLoading={isLoadingSave}
            />
            <ButtonComponent
              color="grey"
              value={t("buttons.abort")}
              onClick={onBack}
            />
          </div>
          <PopupComponent
            isOpen={showDeleteAccountPopup}
            onClose={() => toggleDeleteAccountPopup(false)}
          >
            <div className="settings-page__delete-popup__wrapper">
              <h1>{t("pages.settings.deleteAccount.title")}</h1>
              <p>{t("pages.settings.deleteAccount.contentProvider")}</p>
              <ButtonComponent
                value={t("pages.settings.deleteAccount.stay")}
                onClick={() => toggleDeleteAccountPopup(false)}
              />
              <ButtonComponent
                value={t("pages.settings.deleteAccount.leaveProvider")}
                color="red"
                isLoading={isLoading}
                onClick={() => {
                  toggleLoading(true);
                  openCustomerPortal();
                }}
              />
            </div>
          </PopupComponent>
        </>
      )}
    </>
  );
};
export default ProviderSettings;

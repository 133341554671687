import { useTranslation } from "react-i18next";
import {
  BoxComponent,
  ButtonComponent,
  LayoutComponent,
} from "stempl-component-library";
import { useUser } from "../hooks/useUser";

const ErrorOccured: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { user } = useUser();

  return (
    <LayoutComponent theme={user?.theme}>
      <BoxComponent>
        <h1>{t("pages.error.somethingWentWrong")}</h1>
        <ButtonComponent
          value={t("pages.error.refresh")}
          onClick={() => window?.location?.reload()}
        />
      </BoxComponent>
    </LayoutComponent>
  );
};

export default ErrorOccured;

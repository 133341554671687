import { deleteField, Timestamp } from "firebase/firestore";
import { useContext, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { LoaderComponent } from "stempl-component-library";
import { UserContext } from "../components/PrivateLayoutWrapper";
import { updateProvider } from "../utils/user/User.firebase";
import { Provider } from "../utils/user/User.types";

/**
 * This is a non-functional page to simply update the user, there should be no
 * option for interaction!
 */
const PaymentSuccess: React.FC<{}> = () => {
  const cookieName: string = process.env.REACT_APP_PROVIDER_REGISTER_COOKIE!;
  const [cookies] = useCookies([cookieName]);
  const { user, updateUser } = useContext(UserContext);
  const navigate = useNavigate();

  // on page load this useEffect checks from which page it is called and handles
  // the user update if applicable
  useEffect(() => {
    // hello developer 👋  keep in mind: this only works in a https environment - not on localhost!
    if (!document.referrer.includes("stripe.com")) navigate("/");
    if (user || cookies[cookieName]) {
      const provider: Provider = user ? user : cookies[cookieName];
      updateProvider({
        ...provider,
        privateInformation: {
          ...provider.privateInformation!,
          daysUntilPaymentInformation: deleteField() as Timestamp,
        },
      }).then(() => updateUser().then(() => window?.location.assign("")));
    }
    //eslint-disable-next-line
  }, [user, cookies]);

  return <LoaderComponent fullscreen />;
};

export default PaymentSuccess;

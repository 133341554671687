import { v4 as uuidv4 } from "uuid";
import { Timestamp } from "firebase/firestore";
import { Branche } from "../user/User.types";

/**
 * The card holding the stempls of an user
 */
export interface StemplCard {
  createDate: Timestamp; // technical create date
  activateDate?: Timestamp; // the moment the user activates the card
  userId: string; // the uid of the user
  providerId: string; // the uid of the provider this card is for
  targetAmount: number; // how many stempls an user has to collect
  stempls: Stempl[]; // the actual stempl entries
  activated: boolean; // true if the user activated the card
  displayName?: string; // the display name is fetched on display time and is not persisted
  branche?: Branche; // the branche is fetched on display time and is not persisted
  color?: string; // the color is fetched on display time and is not persisted
  cardIsFull: boolean; //card has as many stempls as targetAmount
  cardDiscount: string; // the reward description
}

/**
 * Wrapper object of the actual Stempl entry
 */
export interface Stempl {
  uid: string; // internally generated
  createDate: Timestamp; // the timestamp of the stempl entry
}

/**
 * Helper to generate a stemplcard with the first values predefined
 *
 * @param userId The uid of the currently logged in user
 * @param providerId The uid of the visited provider
 * @returns A newly generated stemplcard with the first entered stempl
 * @tested
 */
export const generateNewStemplCard = (
  userId: string,
  providerId: string,
  withoutFirstStempl?: boolean
): StemplCard => ({
  activated: false,
  createDate: Timestamp.now(),
  providerId: providerId,
  stempls: withoutFirstStempl
    ? []
    : [
        {
          uid: uuidv4(),
          createDate: Timestamp.now(),
        },
      ],
  targetAmount: 10,
  userId: userId,
  cardIsFull: false,
  cardDiscount: "",
});

/**
 * Helper to generate a new Stempl
 *
 * @returns A new stempl entry with the current timestamp
 */
export const generateNewStempl = (): Stempl => ({
  createDate: Timestamp.now(),
  uid: uuidv4(),
});

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  ButtonComponent,
  generateNotification,
  InputComponent,
} from "stempl-component-library";
import { Stempler } from "../utils/user/User.types";
import { generateNewStemplerForRegister } from "../utils/user/stempler/Stempler.utils";
import { registerNewStemplerWithPassword } from "../utils/user/User.firebase";
import { ReactComponent as EyeIcon } from "../assets/icons/visibility.svg";
import { ReactComponent as EyeIconOff } from "../assets/icons/visibility-off.svg";

const StemplerRegisterForm: React.FC<{}> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [newStempler, setNewStempler] = useState<Stempler>(
    generateNewStemplerForRegister()
  );
  const [isLoading, toggleIsLoading] = useState<boolean>(false);
  const [passwordShown, togglePasswordShown] = useState<boolean>(false);

  /**
   * Helper to show the password entered by the user
   */
  const togglePassword = (): void => {
    togglePasswordShown(!passwordShown);
  };

  return (
    <div className="login-form">
      <h1>{t("pages.login.title")}</h1>
      <p>{t("pages.login.contentUserRegister")}</p>
      <form
        onSubmit={(evt) => {
          evt.preventDefault();
          if (password !== passwordConfirm) {
            generateNotification(t("notifications.notSamePasswords"), "info");
            return;
          }
          toggleIsLoading(true);
          registerNewStemplerWithPassword(newStempler, password).then(
            (success) => {
              if (success) navigate("/");
              toggleIsLoading(false);
            }
          );
        }}
      >
        <InputComponent
          value={newStempler.name}
          required
          label={t("pages.login.firstName")}
          onChange={(newName) =>
            setNewStempler({
              ...newStempler,
              name: newName,
            })
          }
        />
        <InputComponent
          value={newStempler.privateInformation!.email}
          required
          label={t("general.mail")}
          type="email"
          onChange={(newMail) =>
            setNewStempler({
              ...newStempler,
              privateInformation: {
                ...newStempler.privateInformation!,
                email: newMail,
              },
            })
          }
        />

        <div className="login-form--password">
          <InputComponent
            value={password}
            label={t("general.password")}
            type={passwordShown ? "text" : "password"}
            onChange={setPassword}
            required
          />
          <div className="login-form--password--icon">
            {passwordShown ? (
              <EyeIconOff onClick={togglePassword} />
            ) : (
              <EyeIcon onClick={togglePassword} />
            )}
          </div>
        </div>
        <div className="login-form--password-confirm">
          <InputComponent
            value={passwordConfirm}
            label={t("general.passwordConfirm")}
            type={passwordShown ? "text" : "password"}
            onChange={setPasswordConfirm}
            required
          />
        </div>
        <br />
        <ButtonComponent
          type="submit"
          value={t("buttons.register")}
          isLoading={isLoading}
        />
      </form>
    </div>
  );
};

export default StemplerRegisterForm;

import { Theme } from "stempl-component-library";
import { Stempler, UserType } from "../User.types";

/**
 * Helper method to init a new stempler instance
 *
 * @returns An empty stempler
 * @tested
 */
export const generateNewStemplerForRegister = (): Stempler => ({
  name: "",
  type: UserType.STEMPLER,
  uid: undefined!,
  theme: Theme.AUTO,
  privateInformation: {
    email: "",
    authProvider: "local",
  },
});

import { Timestamp } from "firebase/firestore";
import { Theme } from "stempl-component-library";

/**
 * This is the abstract super interface of all
 * user instances (Provider and Stempler)
 */
export interface User {
  uid: string;
  privateInformation?: PrivateUserInformation;
  type: UserType;
  theme: Theme;
  language?: Language;
}

/**
 * An user can either be a provider or a stempler, this
 * type defines which one the user has
 */
export enum UserType {
  PROVIDER = "PROVIDER",
  STEMPLER = "STEMPLER",
}

/**
 * enum for available languages
 */
export enum Language {
  ENGLISH = "en",
  GERMAN = "de",
  ITALIAN = "it",
}

/**
 * This object holds the sensitive information
 * of an user object
 */
export interface PrivateUserInformation {
  authProvider: "local" | "google";
  email: string;
  [key: string]: string | string[] | number | Timestamp | boolean | undefined;
}

/**
 * As the provider have more sensitive information than the
 * normal Stempler user they need to extend them
 */
export interface PrivateProviderInformation extends PrivateUserInformation {
  firstName: string;
  lastName: string;
  street: string;
  zip: string;
  city: string;
  stemplerUids: string[]; // this holds all uids of the stempler this provider issued stempl
  stemplAmount: number; // this holds the total amount of stempls the provider issued
  activatedCardsAmount: number; // this holds the total number of activated cards for this provider
  daysUntilPaymentInformation?: Timestamp;
  registerIsDiscounted: boolean;
}

/**
 * The provider is a specific plattform
 * user object which offers Stemplers to
 * collect Stempl
 */
export interface Provider extends User {
  type: UserType.PROVIDER;
  displayName: string;
  color: string;
  branche: Branche;
  lat?: number; // the coordinates will get filled with geocoding
  long?: number; // the coordinates will get filled with geocoding
  privateInformation?: PrivateProviderInformation;
  cardDiscount: string;
  registerFinished: boolean;
  deleteDate?: Timestamp;
  [key: string]:
    | string
    | Branche
    | number
    | PrivateProviderInformation
    | Set<string>
    | boolean
    | undefined
    | Timestamp;
}

/**
 * The Stempler is a normal end user who collects
 * the Stempl from Provider QR codes
 */
export interface Stempler extends User {
  type: UserType.STEMPLER;
  name: string;
  deleteMe?: boolean;
  [key: string]: string | PrivateUserInformation | boolean | undefined;
}

/**
 * This enum defines the different branches a Provider can be
 * working in.
 */
export enum Branche {
  GYM = "GYM",
  ICE = "ICE",
  BAKERY = "BAKERY",
  SWIM = "SWIM",
  CINEMA = "CINEMA",
  FLOWER = "FLOWER",
  FOOD = "FOOD",
  HAIR = "HAIR",
  DRINK = "DRINK",
  OTHER = "OTHER",
}

import { FirebaseError } from "firebase/app";
import {
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  User,
  UserCredential,
} from "firebase/auth";
import {
  addDoc,
  collection,
  getDocs,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import i18next from "i18next";
import { generateNotification } from "stempl-component-library";
import { auth, db } from "../firebase";
import { PrivateUserInformation, Stempler, UserType } from "./user/User.types";

const googleProvider = new GoogleAuthProvider();

/**
 * The database reference for user (provider and stempler)
 */
const passwordResetDatabase: string =
  process.env.REACT_APP_PASSWORD_RESET_DATABASE!;

/**
 * The database reference for user (provider and stempler)
 */
const emailVerificationDatabase: string =
  process.env.REACT_APP_EMAIL_VERIFICATIONS_DATABASE!;

/**
 * Helper method to sign in using the social login Google provides.
 * This login method should only be accessible to Stempler users. In
 * case it is the first login, a new Stempler entry on the database
 * will get created
 */
export const signInWithGoogle = async (): Promise<void> => {
  const stemplerDatabase: string | undefined =
    process.env.REACT_APP_USER_DATABASE;
  if (!stemplerDatabase) {
    console.error("Stempler database param not set!");
    return;
  }
  try {
    const newEntry: UserCredential = await signInWithPopup(
      auth,
      googleProvider
    );
    //check if there already is a user object for this google account
    const queryForUserObject = query(
      collection(db, stemplerDatabase),
      where("uid", "==", newEntry.user.uid)
    );
    const doc = await getDocs(queryForUserObject);
    //if the doc it not empty there must be an object, therefore we dont create one
    if (!doc.empty) return;
    const user: User = newEntry.user;
    const userRef = await addDoc(collection(db, stemplerDatabase), {
      uid: user.uid,
      name: user.displayName,
      type: UserType.STEMPLER,
    } as Stempler);
    const userPrivateRef = collection(
      db,
      stemplerDatabase,
      userRef.id,
      "privateInformation"
    );
    await addDoc(userPrivateRef, {
      authProvider: "google",
      email: user.email,
    } as PrivateUserInformation);
  } catch (err: any) {
    console.error("Error during stempler creation with google login", err);
  }
};

/**
 * Helper method to login an user with the help of the given mail and password
 *
 * @param email The user entered mail
 * @param password The user entered password
 */
export const logInWithEmailAndPassword = async (
  email: string,
  password: string
): Promise<boolean> => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
    return true;
  } catch (err: any) {
    console.error("Error during login!", err);
    const errorMessage: string = (err as FirebaseError).message;
    if (
      errorMessage.includes("auth/user-not-found") ||
      errorMessage.includes("auth/wrong-password")
    )
      generateNotification(i18next.t("notifications.invalidLogin"), "info");
    else
      generateNotification(i18next.t("notifications.genericError"), "warning");
    return false;
  }
};

/**
 * API method to create a new database entry for the password reset. The cloud
 * functions are generating the mail based upon the DB entry
 *
 * @param email The user entered mail
 */
export const sendPasswordReset = async (email: string): Promise<void> => {
  addDoc(collection(db, passwordResetDatabase), {
    requestDate: Timestamp.now(),
    email: email,
    mailSent: false,
  })
    .then(() =>
      generateNotification(
        i18next.t("notifications.passwordResetSent"),
        "success"
      )
    )
    .catch((exc) => {
      console.error("Error during password reset entry creation", exc);
      generateNotification(i18next.t("notifications.genericError"), "warning");
    });
};

/**
 * API method to create a new database entry for the password reset. The cloud
 * functions are generating the mail based upon the DB entry
 *
 * @param email The user entered mail
 * @name firstname of the user
 */
export const resendEmailVerification = async (
  email: string,
  firstname: string
): Promise<void> => {
  addDoc(collection(db, emailVerificationDatabase), {
    requestDate: Timestamp.now(),
    email: email,
    firstname: firstname,
    mailSent: false,
  })
    .then(() =>
      generateNotification(
        i18next.t("notifications.emailVerificationSent"),
        "success"
      )
    )
    .catch((exc) => {
      console.error("Error during email verification entry creation", exc);
      generateNotification(i18next.t("notifications.genericError"), "warning");
    });
};

/**
 * Helper method to logout the currently logged in user
 */
export const logout = (): void => {
  auth.signOut();
};

/**
 * Helper method to determine if an user is signed or not
 *
 * @returns true if an user is signed in, false otherwise
 */
export const userIsSignedIn = (): boolean => {
  return !!auth.currentUser;
};

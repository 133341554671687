import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BoxComponent,
  ButtonComponent,
  QrScannerComponent,
} from "stempl-component-library";

const ScanView: React.FC<{
  onScan(scanResult: string): void;
  onAbort(): void;
}> = ({ onScan, onAbort }) => {
  const { t } = useTranslation();
  const [camClosed, toggleCamClose] = useState<boolean>(false);

  /**
   * Internal helper to close the camera and the stream as well!
   */
  const handleAbort = (): void => {
    toggleCamClose(true);
    onAbort();
  };

  return (
    <div className="scan-view__wrapper">
      <BoxComponent>
        <span className="scan-view__title">
          {t("pages.dashboard.stempler.scannerTitle")}
        </span>
        <QrScannerComponent
          onScan={(scannedValue) => onScan(scannedValue)}
          camClosed={camClosed}
        />
      </BoxComponent>
      <div className="spacer" />
      <ButtonComponent value={t("buttons.abort")} onClick={handleAbort} />
    </div>
  );
};

export default ScanView;

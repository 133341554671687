import {
  addDoc,
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  getDocs,
  orderBy,
  Query,
  query,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { generateNotification } from "stempl-component-library";
import { db } from "../../firebase";
import i18n from "../../i18n";
import { loadManyProviderByUids } from "../user/User.firebase";
import {
  generateNewStempl,
  generateNewStemplCard,
  Stempl,
  StemplCard,
} from "./Stempl.types";
import { addProviderInformationToStemplCard } from "./Stempl.utils";

/**
 * The database reference for the stempl card entries
 */
export const stemplCardRef: CollectionReference<DocumentData> = collection(
  db,
  process.env.REACT_APP_STEMPL_CARD_DATABASE!
);

/**
 * API method to add a Stempl. This can mean that a new card is created or an
 * existing one is updated with a new entry.
 *
 * @param userId The uid of the currently logged in user
 * @param providerId The uid of the provider the stempl is collected for
 * @returns The updated stemplcard
 */
export const addStempl = async (
  userId: string,
  providerId: string
): Promise<StemplCard> => {
  // first look for an already existing card
  const cardQuery = query(
    stemplCardRef,
    where("userId", "==", userId),
    where("providerId", "==", providerId),
    where("activated", "==", false),
    where("cardIsFull", "==", false)
  );
  let oldStempls: Stempl[] = [];
  let cardIsFull: boolean = false;
  const loadedCard: DocumentReference | undefined = await getDocs(
    cardQuery
  ).then((result) => {
    if (result.empty) {
      return undefined;
    }
    const card: StemplCard = result.docs[0].data() as StemplCard;
    oldStempls = [...card.stempls];
    if (card.stempls.length === card.targetAmount - 1) cardIsFull = true;
    return result.docs[0].ref;
  });

  // the stemplcard already exists
  if (loadedCard) {
    await updateDoc(loadedCard, {
      stempls: [...oldStempls, generateNewStempl()],
      cardIsFull: cardIsFull,
    });
  }
  //when there is no card for this provider yet or the former card is full, a new one is created
  if (!loadedCard || cardIsFull) {
    // the card is new
    await addDoc(
      stemplCardRef,
      generateNewStemplCard(userId, providerId, cardIsFull)
    ).catch((exc) => {
      generateNotification(
        i18n.t("notifications.newStemplCardError"),
        "warning"
      );
      console.error("Error during stemplcard creation!", exc);
    });
  }

  // load the updated stempl cards as return value
  return getDocs(cardQuery).then((result) => {
    if (result.empty) {
      generateNotification(
        i18n.t("notifications.stemplCardFetchError"),
        "warning"
      );
      return undefined!;
    }
    return result.docs[0].data() as StemplCard;
  });
};

/**
 * API method to activate a stemplcard with the given parameter
 *
 * @param userId The uid of the currently logged in user
 * @param providerId The provider of the stemplcard
 */
export const activateStemplCard = async (
  userId: string,
  providerId: string
): Promise<StemplCard | undefined> => {
  const cardQuery = query(
    stemplCardRef,
    where("userId", "==", userId),
    where("providerId", "==", providerId),
    where("activated", "==", false),
    where("cardIsFull", "==", true),
    orderBy("createDate", "asc")
  );
  let cardToActivate: StemplCard | undefined;
  const loadedCard: DocumentReference | undefined = await getDocs(
    cardQuery
  ).then((result) => {
    if (result.empty) return undefined;
    else {
      cardToActivate = result.docs[0]?.data() as StemplCard;
      return result.docs[0]?.ref;
    }
  });
  if (loadedCard) {
    // double check if the card can be activated!
    await updateDoc(loadedCard, {
      activated: true,
      activateDate: Timestamp.now(),
    });
    return cardToActivate;
  } else {
    // this can't be as the card should be there to activate it
    generateNotification(
      i18n.t("notifications.stemplCardActivateError"),
      "warning"
    );
    console.error("Error during stemplcard activation!");
  }
};

/**
 * API method to load all stemplcards of an user
 *
 * @param stemplerUid The uid of the currently logged in stempler user
 * @returns An array of all loaded StemplCards or an empty array in case of error
 */
export const loadStemplCardsForUser = async (
  stemplerUid: string
): Promise<StemplCard[]> => {
  // first of all load all stemplcards of the user
  const cardQuery: Query<DocumentData> = query(
    stemplCardRef,
    where("userId", "==", stemplerUid),
    where("activated", "==", false)
  );
  return getDocs(cardQuery)
    .then((result) => {
      if (result.empty) return [];
      let resultArray: StemplCard[] = [];
      const providerUids: string[] = [];
      result.forEach((card) => {
        resultArray.push(card.data() as StemplCard);
        providerUids.push((card.data() as StemplCard).providerId);
      });
      // load all provider and fill card information with current provider info
      return loadManyProviderByUids(providerUids).then((provider) => {
        addProviderInformationToStemplCard(resultArray, provider);
        return resultArray;
      });
    })
    .catch((exc) => {
      console.error("Error during stemplcard fetch!", exc);
      generateNotification(
        i18n.t("notifications.stemplCardLoadFail"),
        "warning"
      );
      return [];
    });
};

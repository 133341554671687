import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ButtonComponent, InputComponent } from "stempl-component-library";
import {
  logInWithEmailAndPassword,
  sendPasswordReset,
} from "../utils/FirebaseUtils";

const LoginForm: React.FC<{}> = () => {
  const [mail, setMail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, toggleIsLoading] = useState<boolean>(false);
  const [isReset, toggleReset] = useState<boolean>(false);

  return (
    <div className="login-form">
      <h1>{t("pages.login.title")}</h1>
      <p>{t(`pages.login.${isReset ? "resetContent" : "loginContent"}`)}</p>
      <form
        onSubmit={(evt) => {
          evt.preventDefault();
          toggleIsLoading(true);
          if (isReset) {
            sendPasswordReset(mail).then(() => {
              toggleReset(false);
              toggleIsLoading(false);
            });
          } else {
            logInWithEmailAndPassword(mail, password).then((success) => {
              if (success) navigate("/");
              toggleIsLoading(false);
            });
          }
        }}
      >
        <InputComponent
          value={mail}
          label={t("general.mail")}
          type="email"
          onChange={setMail}
          required
        />
        {isReset || (
          <InputComponent
            value={password}
            label={t("general.password")}
            type="password"
            onChange={setPassword}
            required
          />
        )}
        <br />
        <ButtonComponent
          type="submit"
          value={t(`buttons.${isReset ? "reset" : "login"}`)}
          isLoading={isLoading}
        />
        <span
          className="login-form__forgot-password"
          onClick={() => toggleReset((oldValue) => !oldValue)}
        >
          {t(`pages.login.${isReset ? "fromResetToLogin" : "passwordReset"}`)}
        </span>
      </form>
    </div>
  );
};

export default LoginForm;

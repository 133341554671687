import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ButtonComponent,
  CardComponent,
  CardGridComponent,
  generateNotification,
  LoaderComponent,
  VerticalCarouselComponent,
} from "stempl-component-library";
import { ReactComponent as GridIcon } from "../assets/icons/stempl-grid.svg";
import { ReactComponent as ListIcon } from "../assets/icons/stempl-list.svg";
import {
  activateStemplCard,
  addStempl,
  loadStemplCardsForUser,
} from "../utils/stempl/Stempl.firebase";
import { StemplCard } from "../utils/stempl/Stempl.types";
import {
  addProviderInformationToStemplCard,
  extractProviderIdFromQrUrl,
  getLatestStemplDate,
  transformCardsToDisplayCards,
  transformCardToDisplayCard,
  updateStemplCardInArray,
} from "../utils/stempl/Stempl.utils";
import {
  getLogoUrl,
  loadManyProviderByUids,
} from "../utils/user/User.firebase";
import { Provider, Stempler } from "../utils/user/User.types";
import NoStempl from "./NoStempl";
import RedeemDiscount from "./RedeemDiscount";
import ScanView from "./ScanView";

interface StemplerDashboardProps {
  stempler: Stempler;
}

/**
 * Internal enum to identify the selected view type
 */
enum StemplViewType {
  GRID,
  LIST,
}

const StemplerDashboard: React.FC<StemplerDashboardProps> = ({ stempler }) => {
  const [selectedStemplView, setSelectedStemplView] = useState<StemplViewType>(
    StemplViewType.LIST
  );
  const [showScanner, toggleScanner] = useState<boolean>(false);
  const [stemplLoading, toggleStemplLoading] = useState<boolean>(true);
  const [stemplWasAdded, toggleStemplWasAdded] = useState<boolean>(false);
  const [loadedCards, setLoadedCards] = useState<StemplCard[]>([]);
  const [activeCards, setActiveCards] = useState<StemplCard[]>([]);
  const [frontCard, setFrontCard] = useState<number>(-1);
  const [lastUpdatedProviderId, setLastUpdatedProviderId] =
    useState<string>("");
  const [activatedCard, setActivatedCard] = useState<StemplCard>();
  const [updatedCard, setUpdatedCard] = useState<StemplCard>();
  const [imageMap, setImageMap] = useState<Map<string, string>>(
    new Map<string, string>()
  );
  const { t } = useTranslation();

  // load the stemplcards of the logged in stempler user
  useEffect(() => {
    loadStemplCards();
    // eslint-disable-next-line
  }, []);

  /**
   * Wrapper method to load the cards
   */
  const loadStemplCards = async (): Promise<void> => {
    const loadedCards: StemplCard[] = await loadStemplCardsForUser(
      stempler.uid
    );
    setLoadedAndActivatedCards(loadedCards);
    let imageMap: Map<string, string> = new Map<string, string>();
    for (const card of loadedCards) {
      const imagePath: string = await getLogoUrl(card.providerId);
      imageMap.set(card.providerId, imagePath);
    }
    setImageMap(imageMap);
    toggleStemplLoading(false);
  };

  /**
   * Helper to update loadedCards and activated Cards state
   * @param loadedCards
   */
  const setLoadedAndActivatedCards = (loadedCards: StemplCard[]): void => {
    setLoadedCards(loadedCards);
    const currentlyActiveCards: StemplCard[] = loadedCards.filter(
      (card) => !card.cardIsFull
    );
    setActiveCards(currentlyActiveCards);
  };

  /**
   * Helper to handle a scanned QR code
   *
   * @param scannedQrCode The value of the scanned QR code
   */
  const handleQrScan = (scannedQrCode: string): void => {
    const providerId: string | undefined =
      extractProviderIdFromQrUrl(scannedQrCode);
    toggleScanner(false);
    if (!providerId) return;
    toggleStemplLoading(true);
    addStempl(stempler.uid, providerId).then(addStemplInLoadedCards);
  };

  /**
   * Helper to add Stempl in loaded cards when updatedCard is returned from service.
   * Creates new Card, when old one is full and adds provider information to new ones.
   * @param updatedCard card that was updated, can be undefined on error
   */
  const addStemplInLoadedCards = async (
    updatedCard?: StemplCard
  ): Promise<void> => {
    if (!updatedCard) {
      toggleStemplLoading(false);
      return;
    }
    let updatedLoadedCards: StemplCard[] = [...loadedCards];
    const cardCouldBeUpdated: boolean = updateStemplCardInArray(
      updatedLoadedCards,
      updatedCard
    );
    //must be new card, so we fetch provider information too
    if (!cardCouldBeUpdated) {
      const provider: Provider[] = await loadManyProviderByUids([
        updatedCard.providerId,
      ]);
      addProviderInformationToStemplCard([updatedCard], provider);
      updatedLoadedCards.push(updatedCard);
      const imagePath: string = await getLogoUrl(updatedCard.providerId);
      setImageMap((imageMap) =>
        imageMap.set(updatedCard.providerId, imagePath)
      );
    }
    setLoadedAndActivatedCards(updatedLoadedCards);
    setLastUpdatedProviderId(updatedCard.providerId);
  };

  /**
   * removes activatedCard from loaded Cards and sets last updated provider id
   * @param activatedCard
   */
  const removeActivatedCardFromLoaded = (activatedCard: StemplCard): void => {
    let updatedLoadedCards: StemplCard[] = [...loadedCards];
    const index: number = loadedCards.findIndex(
      (card) =>
        card.createDate.isEqual(activatedCard.createDate) &&
        card.providerId === activatedCard.providerId
    );
    if (index === -1) return;
    updatedLoadedCards.splice(index, 1);
    setLoadedAndActivatedCards(updatedLoadedCards);
    setLastUpdatedProviderId(activatedCard.providerId);
  };

  /**
   * Helper method to perform redeem action for current front card
   */
  const redeemCard = () => {
    activateStemplCard(stempler.uid, activeCards[frontCard].providerId).then(
      (card) => {
        if (!card) {
          generateNotification(t("notifications.genericError"), "warning");
          return;
        }
        setActivatedCard(card);
        removeActivatedCardFromLoaded(card);
      }
    );
  };

  //sets frontCard to the card with lastly updated provider id
  useEffect(() => {
    if (activeCards.length > 0 && lastUpdatedProviderId) {
      const frontCardIndex: number = activeCards.findIndex(
        (card) => card.providerId === lastUpdatedProviderId
      );
      if (selectedStemplView === StemplViewType.LIST)
        setFrontCard(frontCardIndex);
      if (activeCards[frontCardIndex].stempls.length > 0)
        setUpdatedCard(activeCards[frontCardIndex]);
      else {
        const fulfilledCards: StemplCard[] = loadedCards.filter(
          (card) =>
            card.providerId === activeCards[frontCardIndex].providerId &&
            card.cardIsFull
        );
        if (fulfilledCards.length === 1) setUpdatedCard(fulfilledCards[0]);
        else if (fulfilledCards.length > 0) {
          fulfilledCards.sort(
            (cardOne, cardTwo) =>
              getLatestStemplDate(cardTwo)!.getTime() -
              getLatestStemplDate(cardOne)!.getTime()
          );
          setUpdatedCard(fulfilledCards[0]);
        }
      }
      setLastUpdatedProviderId("");
      toggleStemplLoading(false);
      if (!activatedCard) toggleStemplWasAdded(true);
    }
    // eslint-disable-next-line
  }, [activeCards, lastUpdatedProviderId]);

  useEffect(() => {
    if (stemplWasAdded) setTimeout(() => toggleStemplWasAdded(false), 3700);
  }, [stemplWasAdded]);

  /**
   * Helper method to check if there are any fulfilled cards for the same provider as front card
   * @returns true if there is a fulfilled card existing for same provider
   */
  const fulfilledCardsForProviderExist = (): boolean => {
    if (frontCard === -1) return false;
    return (
      loadedCards.filter(
        (card) => card.providerId === activeCards[frontCard].providerId
      ).length > 1
    );
  };

  /**
   * Wrapper method to toggle the scanner to on
   */
  const activateScan = (): void => {
    toggleScanner(true);
    toggleStemplWasAdded(false);
  };

  /**
   * Helper to determine which content should be displayed on the dashboard
   *
   * @returns The correct content to display
   */
  const getCorrectContent = (): JSX.Element => {
    if (stemplLoading) return <LoaderComponent />;
    // the user opened the scanner
    if (showScanner)
      return (
        <ScanView onScan={handleQrScan} onAbort={() => toggleScanner(false)} />
      );
    if (activatedCard)
      return (
        <RedeemDiscount
          cardProps={transformCardToDisplayCard({
            ...activatedCard,
            displayName: activeCards[frontCard].displayName,
            branche: activeCards[frontCard].branche,
            color: activeCards[frontCard].color,
          })}
          onDone={() => setActivatedCard(undefined)}
        />
      );
    // the user has 1 or more stempl card
    if (loadedCards.length > 0) {
      const redeemButton: JSX.Element = (
        <ButtonComponent
          key={`redeem-button-${frontCard}`}
          slider
          disabled={frontCard === -1 || !fulfilledCardsForProviderExist()}
          value={t("buttons.redeem")}
          onClick={redeemCard}
        />
      );
      return (
        <>
          {selectedStemplView === StemplViewType.LIST ? (
            <VerticalCarouselComponent
              key={loadedCards.length}
              cards={transformCardsToDisplayCards(
                loadedCards,
                activateScan,
                imageMap
              )}
              setFrontCard={setFrontCard}
              frontCard={frontCard}
            />
          ) : (
            <CardGridComponent
              cards={transformCardsToDisplayCards(
                loadedCards,
                activateScan,
                imageMap
              )}
              detailAction={redeemButton}
              setFrontCard={setFrontCard}
              frontCard={frontCard}
            />
          )}
          <div className="button-wrapper">
            {selectedStemplView === StemplViewType.LIST && redeemButton}
            <ButtonComponent
              value={t("buttons.scan")}
              onClick={() => {
                toggleScanner(true);
                toggleStemplWasAdded(false);
              }}
            />
          </div>
        </>
      );
    }
    // display the no stempl fallback
    return <NoStempl onClick={() => toggleScanner(true)} />;
  };

  return (
    <div className="stempler-dashboard__wrapper">
      <div className="stempler-dashboard__header">
        <div>
          {t("pages.dashboard.stempler.greeting", { name: stempler.name })}
        </div>
        <div>
          <GridIcon
            className={[
              "stempl-view-icon",
              selectedStemplView === StemplViewType.GRID ? "active" : "",
            ].join(" ")}
            onClick={() => {
              setSelectedStemplView(StemplViewType.GRID);
              setFrontCard(-1);
            }}
          />
          <ListIcon
            className={[
              "stempl-view-icon",
              selectedStemplView === StemplViewType.LIST ? "active" : "",
            ].join(" ")}
            onClick={() => setSelectedStemplView(StemplViewType.LIST)}
          />
        </div>
      </div>
      {getCorrectContent()}
      {stemplWasAdded && updatedCard && !stemplLoading && (
        <CardComponent
          {...transformCardToDisplayCard(
            updatedCard,
            () => {},
            loadedCards.filter(
              (currentCard) => currentCard.providerId === updatedCard.providerId
            ).length - 1,
            imageMap.get(updatedCard.providerId)
          )}
          animate
          hideShadow
        />
      )}
    </div>
  );
};
export default StemplerDashboard;

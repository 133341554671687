import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import i18n from "../i18n";
import { Provider, Stempler, User, UserType } from "../utils/user/User.types";

/**
 * Central user hook to quickly access the user object and some helper
 * methods/variables
 *
 * @returns The user object and some helper
 */
export const useUser = (): {
  user: User;
  loading: boolean;
  updateUser: Function;
} => {
  const [user, loading] = useAuthState(auth);
  const authState = useAuthState(auth);
  const [loadedUser, setUser] = useState<User>();
  const [internalLoading, toggleInternalLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  /**
   * Helper method to load the user object from the database
   */
  const fetchUserObject = async () => {
    if (!user) return;
    const usersDatabase: string | undefined =
      process.env.REACT_APP_USER_DATABASE;
    if (!usersDatabase) {
      console.error("User database not defined!");
      navigate("/error");
      return;
    }
    try {
      const q = query(
        collection(db, usersDatabase),
        where("uid", "==", user?.uid)
      );
      const doc = await getDocs(q);
      if (doc.empty) {
        console.error("No user for this uid found");
        navigate("/error");
        return;
      }
      const data = doc.docs[0].data();
      const privateInformation = (
        await getDocs(
          collection(db, doc.docs[0].ref.path, "privateInformation")
        )
      )?.docs[0]?.data();
      if (data.type === UserType.STEMPLER) {
        setUser({
          ...data,
          privateInformation: privateInformation,
        } as Stempler);
      } else if (data.type === UserType.PROVIDER) {
        setUser({
          ...data,
          privateInformation: privateInformation,
        } as Provider);
      } else {
        console.warn("There was an unknown user type given after load!", data);
        navigate("/error");
        return;
      }
      toggleInternalLoading(false);
    } catch (err) {
      console.error("An error occured while fetching user data", err);
      navigate("/error");
    }
  };

  // as soon as the auth user is defined trigger the user loading
  useEffect(() => {
    // index 0 holds the loaded user object and index 1 holds the information
    // if the login is still pending. Only if both are false there is no
    // logged in user at all!
    if (!authState[0] && !authState[1]) {
      setUser(undefined);
      toggleInternalLoading(false);
      return;
    }
    if (user) fetchUserObject();
    // eslint-disable-next-line
  }, [user, loading, authState]);

  //sets the language according to userSettings
  useEffect(() => {
    if (loadedUser?.language) i18n.changeLanguage(loadedUser?.language);
  }, [loadedUser]);

  return {
    user: loadedUser!,
    loading: internalLoading,
    updateUser: fetchUserObject,
  };
};

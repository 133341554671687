import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  BoxComponent,
  ButtonComponent,
  LoaderComponent,
  Theme,
  ToggleComponent,
} from "stempl-component-library";
import { ReactComponent as PenIcon } from "../assets/icons/pen.svg";
import { ReactComponent as ProfileIcon } from "../assets/icons/profile.svg";
import { ReactComponent as SettingsIcon } from "../assets/icons/settings.svg";
import CardSettings from "../components/CardSettings";
import { UserContext } from "../components/PrivateLayoutWrapper";
import ProviderSettings from "../components/ProviderSettings";
import StemplerSettings from "../components/StemplerSettings";
import { updateTheme } from "../utils/user/User.firebase";
import { User, UserType } from "../utils/user/User.types";
import { buildPickerOptionsForThemes } from "../utils/user/User.utils";

const SettingsMenu: React.FC<{}> = () => {
  const { user, setTheme, updateUser } = useContext(UserContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loadedUser, setUser] = useState<User>();
  const [showCardSettings, toggleShowCardSettings] = useState<boolean>(false);
  const [showProfileSettings, toggleShowProfileSettings] =
    useState<boolean>(false);

  //initially sets user when loaded from context
  useEffect(() => {
    if (user) setUser(user);
  }, [user]);

  return (
    <BoxComponent>
      <div className="flex-row">
        <SettingsIcon className="header-icon" />
        <h1>{t("pages.settings.title")}</h1>
      </div>
      {loadedUser ? (
        <>
          {showCardSettings || showProfileSettings || (
            <>
              <ToggleComponent
                label={t("pages.settings.theme")}
                selectedOption={(loadedUser.theme || Theme.AUTO) as string}
                setSelectedOption={(newValue) => {
                  setUser({
                    ...loadedUser,
                    theme: newValue as Theme,
                  });
                  setTheme(newValue as Theme);
                  updateTheme(loadedUser, newValue as Theme);
                }}
                options={buildPickerOptionsForThemes()}
              />
              {loadedUser.type === UserType.PROVIDER && (
                <div
                  className="settings-menu-entry"
                  onClick={() => toggleShowCardSettings(true)}
                >
                  <PenIcon className="settings-menu-icon" />
                  <div>{t("pages.settings.card")}</div>
                </div>
              )}

              <div
                className="settings-menu-entry"
                onClick={() => toggleShowProfileSettings(true)}
              >
                <ProfileIcon className="settings-menu-icon" />
                <div>{t("pages.settings.profile")}</div>
              </div>
              <ButtonComponent
                value={t("buttons.back")}
                onClick={() => navigate("/")}
              />
            </>
          )}

          {showCardSettings && (
            <CardSettings
              onBack={() => {
                toggleShowCardSettings(false);
                updateUser();
              }}
              user={loadedUser}
              setUser={setUser}
            />
          )}
          {showProfileSettings &&
            (user?.type === UserType.PROVIDER ? (
              <ProviderSettings
                onBack={() => {
                  toggleShowProfileSettings(false);
                  updateUser();
                }}
                user={loadedUser}
                setUser={setUser}
              />
            ) : (
              <StemplerSettings
                onBack={() => {
                  toggleShowProfileSettings(false);
                  updateUser();
                }}
                user={loadedUser}
                setUser={setUser}
              />
            ))}
        </>
      ) : (
        <LoaderComponent />
      )}
      <small className="version-wrapper">
        {t("general.version", { replace: { version: "1.0.0" } })}
      </small>
    </BoxComponent>
  );
};
export default SettingsMenu;

import { useContext, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BoxComponent,
  ButtonComponent,
  CardComponent,
  InputComponent,
  PickerComponent,
  UploadComponent,
} from "stempl-component-library";
import { ReactComponent as UploadIcon } from "../assets/icons/file-upload.svg";
import { UserContext } from "../components/PrivateLayoutWrapper";
import { startCheckout } from "../utils/StripeUtils";
import {
  buildDefaultOptionsForCardDiscount,
  buildPickerOptionsForBranches,
  getIconForBranche,
  getPickerOptionWithLogo,
} from "../utils/user/provider/Provider.utils";
import {
  getTempLogoAndUploadForProvider,
  updateProviderAndLogo,
} from "../utils/user/User.firebase";
import { Branche, Provider, UserType } from "../utils/user/User.types";

const ProviderRegister: React.FC<{}> = () => {
  const { t } = useTranslation();
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const cookieName: string = process.env.REACT_APP_PROVIDER_REGISTER_COOKIE!;
  // eslint-disable-next-line
  const [_, setCookies] = useCookies([cookieName]);
  const [provider, setProvider] = useState<Provider>();
  const [registerIsDiscounted, toggleRegisterDiscounted] =
    useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [logo, setLogo] = useState<File>();
  const hiddenUploadRef = useRef<HTMLInputElement>(null);
  const location = useLocation();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  //initially sets loaded user as Provider
  useEffect(() => {
    if (!user) return;
    if (user.type === UserType.STEMPLER) {
      navigate("/");
      return;
    }
    const provider = user as Provider;
    setProvider(provider);
    toggleRegisterDiscounted(
      provider.privateInformation?.registerIsDiscounted || false
    );
    if (location.state) {
      const { logoName, skipCardDesign } = location.state as {
        logoName: string;
        skipCardDesign: boolean;
      };
      if (skipCardDesign) setCurrentStep(4);
      getTempLogoAndUploadForProvider(logoName, provider.uid).then((file) => {
        if (file) setLogo(file);
      });
    }
    // eslint-disable-next-line
  }, [user, location]);

  //scrolls to top of div after clicking next to smooth process
  useEffect(() => {
    //since there is only one layoutComponent, we can easily get it by className
    const content: Element = document.getElementsByClassName(
      "layout-component--content"
    )[0];
    if (!content) return;
    content.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentStep]);

  /**
   * Helper to get inidual discount value, will replace individual value when same as one of default values
   * @returns cardDiscount that is not a default value
   */
  const getIndividualDiscount = (): string => {
    if (
      provider?.cardDiscount === "-15%" ||
      provider?.cardDiscount === "-10%" ||
      provider?.cardDiscount === "-5%"
    ) {
      return "";
    }
    return provider?.cardDiscount || "";
  };

  /**
   * Helper to save Changes and start checkout after finishing registration
   */
  const saveChangesAndStartCheckout = async (): Promise<void> => {
    toggleLoading(true);
    const providerWithFinishedFlag: Provider = {
      ...provider!,
      registerFinished: true,
    };
    const successfullyUpdated: boolean = await updateProviderAndLogo(
      providerWithFinishedFlag,
      logo
    );
    if (!successfullyUpdated) {
      toggleLoading(false);
      return;
    }
    setCookies(
      process.env.REACT_APP_PROVIDER_REGISTER_COOKIE!,
      providerWithFinishedFlag
    );
    startCheckout(
      providerWithFinishedFlag.uid,
      () => toggleLoading(false),
      registerIsDiscounted
    );
  };

  return (
    <>
      {currentStep === 4 ? (
        <div className="provider-register__text">
          <div>{t("pages.register.tada")}</div>
          <div>{t("pages.register.thisIsYourCard")}</div>
          <br />
          <div className="centered">
            <CardComponent
              currentStempl={4}
              displayName={provider!.displayName || ""}
              icon={getIconForBranche(provider!.branche)}
              bgColor={provider!.color}
              logoPath={logo ? URL.createObjectURL(logo) : undefined}
              onPlusClick={() => {}}
              rewardText={t("pages.dashboard.stempler.fulfillPreview", {
                replace: { discount: provider!.cardDiscount },
              })}
              flippable
            />
          </div>
          <br />
          <br />
          <div className="button-wrapper">
            <ButtonComponent
              color="darkgrey"
              value={t("buttons.back")}
              onClick={() => setCurrentStep(currentStep - 1)}
            />
            <ButtonComponent
              value={t("buttons.letsGo")}
              isLoading={isLoading}
              onClick={() => saveChangesAndStartCheckout()}
            />
          </div>
        </div>
      ) : (
        <BoxComponent>
          <input
            hidden
            type="file"
            accept="image/*"
            onChange={(event) => {
              if (event.target.files && event.target.files.length > 0)
                setLogo(event.target.files[0]);
            }}
            ref={hiddenUploadRef}
          />
          {provider && (
            <>
              <div
                className={[
                  currentStep === 3 ? "transition-display" : "",
                  "transition-hidden",
                ].join(" ")}
              >
                <h1>{t("pages.register.cardDiscount.heading")}</h1>
                <div className="provider-register__text">
                  {t("pages.register.cardDiscount.question")}
                </div>
                <PickerComponent
                  selectedOption={provider.cardDiscount}
                  setSelectedOption={(newValue) =>
                    setProvider({ ...provider, cardDiscount: newValue })
                  }
                  options={buildDefaultOptionsForCardDiscount()}
                />
                <br />
                <div className="provider-register__text">
                  {t("pages.register.cardDiscount.noFit")}
                  <br />
                  {t("pages.register.cardDiscount.chooseVariable")}
                </div>
                <InputComponent
                  value={getIndividualDiscount()}
                  onChange={(newValue) =>
                    setProvider({
                      ...provider,
                      cardDiscount: newValue || "-5%",
                    })
                  }
                />
                <br />
              </div>
              <div
                className={[
                  currentStep === 2 ? "transition-display" : "",
                  "transition-hidden",
                ].join(" ")}
              >
                <div className="provider-register__text centered">
                  {t("pages.register.logo", {
                    displayName: provider.displayName,
                  })}
                </div>
                <div
                  className="provider-register__upload"
                  onClick={() => hiddenUploadRef?.current?.click()}
                >
                  {logo ? (
                    <img src={URL.createObjectURL(logo)} alt="Logo" />
                  ) : (
                    <UploadIcon />
                  )}
                </div>
                <div className="button-wrapper">
                  <ButtonComponent
                    color="grey"
                    value={t("pages.settings.provider.removeLogo")}
                    onClick={(files) => setLogo(undefined)}
                  />
                  <UploadComponent
                    buttonText={t("pages.settings.provider.uploadLogo")}
                    addFiles={(files) => setLogo(files[0])}
                  />
                </div>
                <div className="separator" />
              </div>
              <div
                className={[
                  currentStep === 1 ? "transition-display" : "",
                  "transition-hidden",
                ].join(" ")}
              >
                <PickerComponent
                  label={t("pages.settings.provider.chooseBranche")}
                  options={buildPickerOptionsForBranches()}
                  setSelectedOption={(branche) =>
                    setProvider({
                      ...provider,
                      branche: branche as Branche,
                    })
                  }
                  selectedOption={provider.branche}
                />
                <div className="separator" />
                <PickerComponent
                  label={t("pages.settings.provider.noFittingBranche")}
                  options={[getPickerOptionWithLogo()]}
                  setSelectedOption={(branche) =>
                    setProvider({
                      ...provider,
                      branche: branche as Branche,
                    })
                  }
                  selectedOption={provider.branche}
                />
              </div>
              <div
                className={[
                  currentStep === 0 ? "transition-display" : "",
                  "transition-hidden",
                ].join(" ")}
              >
                <PickerComponent
                  label={t("pages.settings.provider.chooseColor")}
                  useColorOptions
                  setSelectedOption={(newColor) =>
                    setProvider({ ...provider, color: newColor })
                  }
                  selectedOption={provider.color}
                />
              </div>
            </>
          )}
          <br />
          <div className="button-wrapper">
            {currentStep !== 0 && (
              <ButtonComponent
                color="grey"
                value={t("buttons.back")}
                onClick={() => setCurrentStep(currentStep - 1)}
              />
            )}
            {currentStep !== 3 && (
              <ButtonComponent
                value={t("buttons.next")}
                onClick={() => setCurrentStep(currentStep + 1)}
              />
            )}
            {currentStep === 3 && (
              <ButtonComponent
                value={t("buttons.done")}
                isLoading={isLoading}
                onClick={() => setCurrentStep((currentStep) => currentStep + 1)}
              />
            )}
          </div>
          <br />
          <br />
          {provider && (
            <CardComponent
              currentStempl={4}
              displayName={provider!.displayName || ""}
              icon={getIconForBranche(provider!.branche)}
              bgColor={provider!.color}
              logoPath={logo ? URL.createObjectURL(logo) : undefined}
              onPlusClick={() => {}}
              rewardText={t("pages.dashboard.stempler.fulfillPreview", {
                replace: { discount: provider!.cardDiscount },
              })}
              flippable
            />
          )}
        </BoxComponent>
      )}
    </>
  );
};

export default ProviderRegister;
